import React from 'react'

export const impactHover = () => {
  return (
    <svg
      width="50"
      height="61"
      viewBox="0 0 50 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.0726 20.2207L0.255249 0.450684V17.9081L27.8215 30.3506L44.0726 23.0178V20.2207Z"
        fill="white"
      />
      <path
        d="M44.0726 37.6781V24.751L29.7443 31.2145L44.0726 37.6781Z"
        fill="white"
      />
      <path
        d="M44.0727 23.0181V24.7512L47.4546 23.2235V38.9479L5.22281 58.0067V42.2824L29.7443 31.2148L27.8216 30.3509L3.63721 41.2604V60.4509L49.0349 39.9698V20.7793L44.0727 23.0181Z"
        fill="white"
      />
    </svg>
  )
}
