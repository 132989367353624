import { createUseStyles } from 'react-jss'

import { MQ } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    borderRadius: '50%',
    width: '45%',
    margin: 'auto',
    filter: 'grayscale(1)',
    [MQ.TABLET]: {
      width: '66%',
    },
    [MQ.DESKTOP]: {
      width: '65%',
    },
    //safari needs this for some reason
    '& picture': {
      '& img': {
        borderRadius: '50%',
      },
    },
  },
  title: {
    width: '100%',
    margin: [40, 20, 25],
    textTransform: 'uppercase',
    [MQ.TABLET]: {
      textAlign: 'center',
      margin: [40, 0, 25],
      maxWidth: 270,
    },
  },
  body: {
    width: '100%',
    textAlign: 'center',
    overflowWrap: 'break-word',
    [MQ.TABLET]: {
      textAlign: 'center',
    },
  },
  buttonWrapper: {
    paddingTop: 10,
  },
  middleImage: {
    width: '40%',

    [MQ.TABLET]: {
      width: '50%',
    },
    [MQ.DESKTOP]: {
      width: '38%',
    },
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  middleLink: {
    margin: [18, 0, 35],
    [MQ.TABLET]: {
      margin: [25, 0, 35],
    },
    [MQ.DESKTOP]: {
      margin: [25, 0, 35],
    },
  },
})
