import { createUseStyles } from 'react-jss'

import { COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    backgroundColor: COLOR.WHITE,
  },
  lightBlue: {
    background: COLOR.BLUE,
  },
  container: {
    width: '85%',
    margin: 'auto',
    padding: [80, 0, 110],
    [MQ.TABLET]: {
      width: '80%',
      padding: [100, 0],
    },
    [MQ.DESKTOP]: {
      width: '69%',
      padding: [160, 0, 200],
      maxWidth: 1077,
    },
  },
  title: {
    color: COLOR.BLACK,
    textAlign: 'center',
    width: '75%',
    margin: 'auto',
    padding: [0, 0, 25],
    [MQ.TABLET]: {
      width: '55%',
      padding: [0, 0, 50],
    },
    [MQ.DESKTOP]: {
      width: '65%',
    },
  },
  white: {
    color: COLOR.WHITE,
  },
  playButton: {
    backgroundColor: COLOR.BLACK,
    borderRadius: '50%',
    width: 80,
    height: 80,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate( -50%, -50%)',
    border: 0,
    '&:active': {
      width: 82.5,
      height: 82.5,
      transition: '0.05s',
    },
    '&:hover': {
      backgroundColor: COLOR.BLACK,
    },
    '&::after': {
      position: 'absolute',
      left: '55%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      content: `""`,
      width: '0',
      height: '0',
      borderStyle: 'solid',
      borderWidth: '18px 0 18px 30px',
      borderColor: 'transparent transparent transparent #ffffff',
      [MQ.DESKTOP]: {
        left: '55%',
        borderWidth: '20px 0 20px 35px',
      },
    },
  },
  video: {
    width: '100%',
  },
  hidden: {
    position: 'absolute',
    left: '-300vw',
  },
})
