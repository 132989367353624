import { format } from 'date-fns'
import { isNilOrEmpty } from 'ramdasauce'

export const formatDateUrl = (date) => {
  const publishDt = new Date(date)
  // handles timezone offset issue
  const dtDateOnly = new Date(
    publishDt.valueOf() + publishDt.getTimezoneOffset() * 60 * 1000
  )
  if (isNilOrEmpty(date)) {
    return null
  }
  return format(dtDateOnly, 'yyyy/dd/MM')
}
