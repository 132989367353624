import { createUseStyles } from 'react-jss'
import { TYPOGRAPHY, MQ } from '@constants'

export const useStyles = createUseStyles({
  example: {
    ...TYPOGRAPHY.PROXIMA_NOVA,
    lineHeight: 1.5,
  },
  header1: {
    ...TYPOGRAPHY.PROXIMA_NOVA,
    fontSize: 36,
    lineHeight: 1.1,
    fontWeight: 700,
    [MQ.TABLET]: {
      fontSize: 48,
    },
    [MQ.DESKTOP]: {
      fontSize: 64,
    },
  },
  header2: {
    ...TYPOGRAPHY.PROXIMA_NOVA_BOLD,
    fontSize: 36,
    fontWeight: 700,
    lineHeight: 1.1,
    letterSpacing: '0.05em',
    [MQ.TABLET]: {
      fontSize: 40,
    },
    [MQ.DESKTOP]: {
      fontSize: 48,
    },
  },
  header3: {
    ...TYPOGRAPHY.PROXIMA_NOVA,
    fontSize: 17,
    lineHeight: 1.1,

    fontWeight: 700,
    [MQ.DESKTOP]: {
      fontSize: 21,
    },
  },
  header4: {
    ...TYPOGRAPHY.PROXIMA_NOVA,
    fontSize: 36,
    lineHeight: 1.1,
    fontWeight: 700,
    letterSpacing: '0.1em',
  },
  header5: {
    ...TYPOGRAPHY.PROXIMA_NOVA,
    fontSize: 30,
    fontWeight: 700,
    lineHeight: 1.1,
    [MQ.TABLET]: {
      fontSize: 24,
    },
    [MQ.DESKTOP]: {
      fontSize: 36,
    },
  },
  header6: {
    ...TYPOGRAPHY.PROXIMA_NOVA,
    fontSize: 36,
    lineHeight: 1.1,
    fontWeight: 700,

    [MQ.DESKTOP]: {
      fontSize: 64,
    },
  },
  header7: {
    ...TYPOGRAPHY.PROXIMA_NOVA,
    fontSize: 30,
    lineHeight: 1.1,
    fontWeight: 700,
    [MQ.TABLET]: {
      fontSize: 36,
    },
    [MQ.DESKTOP]: {
      fontSize: 64,
    },
  },

  body1: {
    ...TYPOGRAPHY.PROXIMA_NOVA,
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: '0.05em',
    lineHeight: 1.55,
    [MQ.DESKTOP]: {
      fontSize: 18,
    },
  },
  body2: {
    ...TYPOGRAPHY.PROXIMA_NOVA,
    fontSize: 18,
    lineHeight: 1.1,
    fontWeight: 400,

    [MQ.TABLET]: {
      fontSize: 14,
    },
    [MQ.DESKTOP]: {
      fontSize: 24,
    },
  },
  body3: {
    ...TYPOGRAPHY.PROXIMA_NOVA_BOLD,
    letterSpacing: '0.25em',
    lineHeight: 1.2,
    fontSize: 24,
  },

  button: {
    ...TYPOGRAPHY.PROXIMA_NOVA,
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 1.1,
    [MQ.DESKTOP]: {
      fontSize: 21,
    },
  },
  subhead1: {
    ...TYPOGRAPHY.PROXIMA_NOVA,
    fontSize: 18,
    lineHeight: 1.1,

    [MQ.TABLET]: {
      fontSize: 24,
    },
  },
  subhead2: {
    ...TYPOGRAPHY.PROXIMA_NOVA_BOLD,
    letterSpacing: '0.25em',
    lineHeight: 1.2,
    fontSize: 16,
  },
  subhead3: {
    ...TYPOGRAPHY.PROXIMA_NOVA_BOLD,
    letterSpacing: '0.05em',
    lineHeight: 1.2,
    fontSize: 12,
  },
  subhead4: {
    ...TYPOGRAPHY.PROXIMA_NOVA,
    letterSpacing: '0.05em',
    lineHeight: 1.2,
    fontSize: 12,
  },
  label: {
    ...TYPOGRAPHY.PROXIMA_NOVA,
    fontSize: 20,
    lineHeight: 1.3,
    fontWeight: 600,
  },
  footer1: {
    ...TYPOGRAPHY.PROXIMA_NOVA,
    fontSize: 14,
    [MQ.TABLET]: {
      fontSize: 18,
    },
    [MQ.DESKTOP]: {
      fontSize: 21,
    },
  },
  footer2: {
    ...TYPOGRAPHY.PROXIMA_NOVA,
    fontSize: 14,
    [MQ.TABLET]: {
      fontSize: 21,
    },
  },
})
