import React, { useEffect, useState } from 'react'
import { isNilOrEmpty, dotPath } from 'ramdasauce'
import { addDays } from 'date-fns'

import { useStyles } from './styles'
import { RichText } from '@atoms'

export const FlashMessage = ({ data }) => {
  const classes = useStyles()

  const [isExpired, setIsExpired] = useState(false)

  const bannerLink = dotPath('bannerLink', data)
  const text = dotPath('text', data)

  const currentDate = Date.parse(new Date())

  const ConditionalLink = ({ children }) => {
    if (!isNilOrEmpty(bannerLink)) {
      return (
        <a href={bannerLink} className={classes.link}>
          {children}
        </a>
      )
    } else {
      return children
    }
  }

  useEffect(() => {
    const expirationDate =
      typeof window !== undefined && localStorage.getItem(`expirationDate`)
    setIsExpired(
      isNilOrEmpty(expirationDate) ? true : expirationDate < currentDate
    )
  }, [currentDate])

  const handleBannerClose = () => {
    const yearFromLogin = addDays(new Date(), 1)
    typeof window !== undefined &&
      localStorage.setItem(`expirationDate`, yearFromLogin)
    setIsExpired(false)
  }

  return (
    isExpired && (
      <div className={classes.wrapper}>
        <div className={classes.text}>
          <ConditionalLink>
            <RichText size="subhead4" data={text} />
          </ConditionalLink>
        </div>
        <button className={classes.close} onClick={handleBannerClose}>
          <div className={classes.x} />
        </button>
      </div>
    )
  )
}
