import React from 'react'

export const blogHover = () => {
  return (
    <svg
      width="61"
      height="68"
      viewBox="0 0 61 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.6709 20.0471C36.712 19.5332 36.7429 19.0603 36.7429 18.5566C36.7527 16.0233 36.215 13.5178 35.1668 11.2115C34.1186 8.90513 32.5845 6.85248 30.6696 5.19395C28.7546 3.53542 26.5039 2.31015 24.0716 1.602C21.6392 0.893844 19.0826 0.719513 16.5766 1.09093C14.0706 1.46236 11.6745 2.37076 9.55207 3.754C7.42968 5.13723 5.63117 6.96264 4.27959 9.10533C2.92801 11.248 2.05527 13.6574 1.72108 16.1686C1.3869 18.6798 1.59916 21.2336 2.34334 23.6552H26.3454L14.4883 35.5123C17.9229 36.4529 21.5617 36.3329 24.9269 35.1679C25.2153 31.7827 26.4778 28.5534 28.5619 25.8701C30.6459 23.1868 33.4623 21.1644 36.6709 20.0471Z"
        fill="white"
      />
      <path
        d="M2.3434 45.473V25.1972H2.85736C2.64981 24.6927 2.46797 24.1781 2.31256 23.6553H0.801514V49.1992L14.4883 35.5124C13.9281 35.3582 13.3833 35.1783 12.8488 34.9984L2.3434 45.473Z"
        fill="white"
      />
      <path
        d="M59.275 41.7521C60.2166 38.6468 60.2805 35.3414 59.4596 32.202C58.6387 29.0626 56.9651 26.2116 54.624 23.9647C52.2828 21.7177 49.3656 20.1625 46.1951 19.4711C43.0247 18.7797 39.7248 18.9792 36.6607 20.0474C36.3749 23.4321 35.1147 26.6614 33.0323 29.3449C30.9499 32.0284 28.1346 34.051 24.9269 35.1682C24.8858 35.6822 24.8601 36.155 24.8601 36.6587C24.8585 39.3671 25.4827 42.0393 26.6839 44.4668C27.8851 46.8943 29.6308 49.0115 31.785 50.6532C33.9391 52.2949 36.4434 53.4168 39.1025 53.9313C41.7616 54.4459 44.5036 54.3391 47.1147 53.6195L35.237 41.7521H59.275Z"
        fill="white"
      />
      <path
        d="M60.8016 41.7515H59.2597C59.1004 42.2654 58.9205 42.7794 58.7149 43.2934H59.2288V63.5743L48.7543 53.0689C48.2403 53.2745 47.6698 53.4544 47.1147 53.5829L60.8016 67.2749V41.7515Z"
        fill="white"
      />
    </svg>
  )
}
