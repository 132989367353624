import * as React from 'react'
import { dotPath, isNilOrEmpty } from 'ramdasauce'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import { useStyles } from './styles'
import { Text, Animated } from '@atoms'
import { DonationForm } from '@organisms'

export const HeroTextImage = ({ data }) => {
  const classes = useStyles()

  const title = dotPath('title', data)
  const subtitle = dotPath('subtitle.subtitle', data)
  const image = dotPath('image', data)
  const color = dotPath('color', data)
  const colorLower = !isNilOrEmpty(color) && color.toLowerCase()
  const isDonateForm = !isNilOrEmpty(dotPath('donateButtonText', data))

  const textBlockClasses = cn({
    [classes.textBlock]: true,
    [classes[colorLower]]: !isNilOrEmpty(color),
    [classes.donate]: isDonateForm,
  })

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        {!isNilOrEmpty(image) && (
          <Animated type="fade-in">
            <div className={classes.imageWrapper}>
              <GatsbyImage
                image={getImage(image)}
                alt={title}
                loading="eager"
                className={classes.image}
              />
            </div>
          </Animated>
        )}

        <Animated type="fade-left">
          <div className={textBlockClasses}>
            {isDonateForm ? (
              <>
                {!isNilOrEmpty(title) && (
                  <Text tag="h1" size="header2">
                    {title}
                  </Text>
                )}
                <DonationForm data={data} />
              </>
            ) : (
              <>
                {!isNilOrEmpty(title) && (
                  <Text tag="h1" size="header2">
                    {title}
                  </Text>
                )}
                {!isNilOrEmpty(subtitle) && (
                  <Text tag="h2" size="body1">
                    {subtitle}
                  </Text>
                )}
              </>
            )}
          </div>
        </Animated>
      </div>
    </div>
  )
}
