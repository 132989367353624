import { createUseStyles } from 'react-jss'

import { MQ } from '@constants'

export const useStyles = createUseStyles({
  image: {
    maxHeight: 200,
    [MQ.TABLET]: {
      maxHeight: 150,
    },
    [MQ.DESKTOP]: {
      maxHeight: 250,
    },
  },
})
