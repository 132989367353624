import React from 'react'

import { globalStyles } from '@themes/global'
import '@themes/global.css'
import { useStyles } from './styles'

export const MasterContainer = ({ children }) => {
  globalStyles()
  const classes = useStyles()
  return <div className={classes.masterContainer}>{children}</div>
}
