import { createUseStyles } from 'react-jss'

export const globalStyles = createUseStyles({
  '@global': {
    html: {
      boxSizing: 'border-box',
    },
    body: {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
    },
    'h1, h2, h3, h4, h5, h6': {
      margin: 0,
    },
    div: {
      boxSizing: 'border-box',
    },
    p: {
      margin: 0,
    },
    button: {
      cursor: 'pointer',
    },
    'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
      {
        WebkitAppearance: 'none',
        MozAppearance: 'none',
        appearance: 'none',
        margin: '0',
      },
  },
})
