import { createUseStyles } from 'react-jss'

import { COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    background: COLOR.WHITE,
    color: COLOR.BLACK,
    textAlign: 'center',
    margin: [80, 0, 0],

    [MQ.TABLET]: {
      margin: [100, 0, 20],
    },
    [MQ.DESKTOP]: {
      margin: [125, 0, 45],
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [MQ.TABLET]: {
      alignItems: 'baseline',
      width: '83%',
      margin: 'auto',
      maxWidth: 1295,
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      justifyItems: 'center',
    },
    [MQ.TABLET_LARGE]: {
      gridTemplateColumns: '33% 33% 33%',
    },
  },
  title: {
    marginBottom: 55,
    width: '80%',
    margin: 'auto',
    color: COLOR.BLACK,

    [MQ.TABLET]: {
      width: '44%',
    },
    [MQ.DESKTOP]: {
      width: '43%',
      marginBottom: 70,
    },
  },
  itemWrapper: {
    width: '80%',
    paddingBottom: 80,
    [MQ.TABLET]: {
      width: '84.2%',
    },
  },
  fourItems: {
    [MQ.TABLET_LARGE]: {
      gridTemplateColumns: '25% 25% 25% 25%',
      width: '86%',
    },
  },
})
