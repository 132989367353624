import React from 'react'

export const contact = () => {
  return (
    <svg
      width="61"
      height="59"
      viewBox="0 0 61 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.1425 39.3207H18.849L1.5354 0.659668H16.8245L34.1425 39.3207Z"
        stroke="#D11A1F"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M60.5229 39.3207H45.2338L27.9202 0.659668H43.2092L60.5229 39.3207Z"
        fill="#E9A73E"
      />
      <path
        d="M13.3993 58.3788C17.6671 58.3788 21.1268 54.893 21.1268 50.593C21.1268 46.293 17.6671 42.8071 13.3993 42.8071C9.13157 42.8071 5.67188 46.293 5.67188 50.593C5.67188 54.893 9.13157 58.3788 13.3993 58.3788Z"
        fill="#D499DD"
      />
      <path
        d="M44.2218 58.3788C48.4896 58.3788 51.9493 54.893 51.9493 50.593C51.9493 46.293 48.4896 42.8071 44.2218 42.8071C39.9541 42.8071 36.4944 46.293 36.4944 50.593C36.4944 54.893 39.9541 58.3788 44.2218 58.3788Z"
        fill="#277AAD"
      />
    </svg>
  )
}
