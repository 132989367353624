import { createUseStyles } from 'react-jss'

import { COLOR, MQ, Z_INDEX } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    background: COLOR.WHITE,
    padding: [60, 0],
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [MQ.TABLET]: {
      padding: [100, 0, 60],
      paddingBottom: 60,
    },
    [MQ.DESKTOP]: {
      paddingBottom: 72,
    },
  },
  altWrapper: {
    backgroundColor: COLOR.GREEN,
  },

  title: {
    color: COLOR.BLACK,
    textAlign: 'center',
    margin: 'auto',
    marginBottom: 15,
    zIndex: Z_INDEX.CONTENT,
    position: 'relative',
    width: '80%',
    paddingBottom: 60,
    [MQ.DESKTOP]: {
      width: '50%',
      paddingBottom: 100,
    },
  },

  body: {
    width: '50%',
    color: COLOR.WHITE,
    margin: 'auto',
    marginBottom: 50,
    textAlign: 'center',
    zIndex: Z_INDEX.CONTENT,
    position: 'relative',
    [MQ.DESKTOP]: {
      marginBottom: 100,
    },
  },
  backgroundImageWrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    top: 0,
  },
  backgroundImage: {
    zIndex: Z_INDEX.BACKGROUND,
    height: '100%',
  },

  videoContainer: {
    display: 'inline-flex',
    flexWrap: 'wrap',
    color: COLOR.WHITE,
    width: 297,
    gap: '50px',
    margin: 'auto',
    zIndex: Z_INDEX.CONTENT,
    position: 'relative',
    [MQ.TABLET]: {
      gap: '75px 8.5%',
      width: '87%',
    },
    [MQ.DESKTOP]: {
      gap: '100px 8.5%',
      width: '81%',
      maxWidth: 1263,
    },
  },
  video: {
    border: `6px solid ${COLOR.WHITE}`,
    borderRadius: 6,
    width: 285,
    height: 162,
    [MQ.TABLET]: {
      width: 200,
      height: 113,
    },
    [MQ.TABLET_MEDIUM]: {
      width: 285,
      height: 162,
    },
    [MQ.DESKTOP]: {
      width: 455,
      height: 255,
    },
  },
  viewMore: {
    outline: 'none',
    color: COLOR.WHITE,
    zIndex: Z_INDEX.CONTENT,
    position: 'relative',
    margin: [35, 0],
    cursor: 'pointer',
    borderRadius: 118,
    backgroundColor: 'transparent',
    border: `2px solid ${COLOR.BLACK}`,
    [MQ.TABLET]: {
      margin: [75, 0],
    },
    [MQ.DESKTOP]: {
      margin: [100, 0],
    },
    '& span': {
      padding: [15, 24, 12],
      textDecoration: 'none',
      display: 'inline-block',
      color: COLOR.BLACK,

      [MQ.DESKTOP]: {
        padding: [18, 45, 16],
      },
    },
  },
  tileWrapper: {
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'fit-content',
    [MQ.TABLET]: {
      width: '45.75%',
    },
  },
})
