import * as React from 'react'
import { dotPath, isNilOrEmpty } from 'ramdasauce'
import { format } from 'date-fns'

import { useStyles } from './styles'
import { Text } from '@atoms'

export const BlogHero = ({ data }) => {
  const classes = useStyles()

  const title = dotPath('title', data)
  const tag = dotPath('tag', data)

  const publishDate = dotPath('publishDate', data)
  const publishDt = new Date(publishDate)
  // handles timezone offset issue
  const dtDateOnly = new Date(
    publishDt.valueOf() + publishDt.getTimezoneOffset() * 60 * 1000
  )
  const formattedDate = format(new Date(dtDateOnly), 'MMMM, d yyyy')

  return (
    <div className={classes.wrapper}>
      {!isNilOrEmpty(tag) && (
        <Text className={classes.blogTitle} tag="p">
          {tag}
        </Text>
      )}
      {!isNilOrEmpty(title) && (
        <Text size="header2" tag="h1" className={classes.title}>
          {title}
        </Text>
      )}
      {!isNilOrEmpty(formattedDate) && (
        <Text tag="p" size="subhead4" className={classes.date}>
          {formattedDate}
        </Text>
      )}
    </div>
  )
}
