import React, { useState } from 'react'
import cn from 'classnames'
import { useStyles } from './styles'
import { isNilOrEmpty } from 'ramdasauce'

export const InputSelect = ({
  name,
  label,
  error,
  inputRef,
  currentValue,
  options,
  register,
  validations,
}) => {
  const [showOptions, setShowOptions] = useState(false)
  const classes = useStyles()

  const labelClasses = cn({
    [classes.label]: true,
    [classes.error]: !isNilOrEmpty(error),
    [classes.active]: !isNilOrEmpty(currentValue),
  })

  const arrowClasses = cn({
    [classes.arrow]: true,
    [classes.arrowActive]: showOptions,
  })

  const dropdownClasses = cn({
    [classes.dropdown]: true,
    [classes.open]: showOptions,
  })

  const labelText = () => {
    return isNilOrEmpty(error) ? label : error.message
  }

  const inputValue = () => {
    if (isNilOrEmpty(currentValue)) return ''
    return options.find((o) => o.VALUE === currentValue).LABEL
  }

  const openDropdown = () => {
    Array.from(document.getElementsByClassName(classes.open)).forEach(
      (dropdown) => {
        dropdown.classList.remove(classes.open)
      }
    )

    setShowOptions(!showOptions)
  }

  const renderOptions = () => {
    return Object.keys(options).map((key) => {
      const option = options[key]

      const optionClasses = cn({
        [classes.option]: true,
        [classes.selected]: currentValue === option.VALUE,
      })

      return (
        <label
          key={option.VALUE}
          htmlFor={option.VALUE}
          className={optionClasses}
        >
          <input
            type="radio"
            name={name}
            id={option.VALUE}
            ref={inputRef}
            className={classes.radio}
            value={option.VALUE}
            onChange={() => setShowOptions(false)}
            {...register(name, validations)}
          />
          {option.LABEL}
        </label>
      )
    })
  }

  return (
    <div className={classes.wrapper}>
      <label className={labelClasses} htmlFor={name}>
        {labelText()}
      </label>

      <input
        readOnly
        id={name}
        onClick={openDropdown}
        className={classes.button}
        value={inputValue()}
      />

      <div className={dropdownClasses}>{renderOptions()}</div>

      <div className={classes.arrowWrapper}>
        <span className={arrowClasses}></span>
      </div>
    </div>
  )
}
