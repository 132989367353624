import { createUseStyles } from 'react-jss'

import { MQ, COLOR } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    background: COLOR.GREY_1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: [50, 0],
    maxWidth: 1560,
    margin: [0, 'auto'],
    [MQ.TABLET]: {
      padding: [80, 0],
    },
    [MQ.TABLET_LARGE]: {
      padding: [120, 0, 70],
    },
  },
  title: {
    width: '90%',
    textAlign: 'center',
    padding: [0, 0, 35],
    [MQ.TABLET]: {
      padding: [0, 0, 55],
    },
    [MQ.TABLET_LARGE]: {
      padding: [0, 0, 65],
    },
    [MQ.DESKTOP]: {
      padding: [0, 0, 90],
    },
  },
  gridWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    [MQ.TABLET]: {
      width: '83%',
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      justifyItems: 'center',
    },
    [MQ.TABLET_LARGE]: {
      gridTemplateColumns: '33% 33% 33%',
    },
  },
  newsTileWrapper: {
    width: '90%',
    maxWidth: 425,
    [MQ.TABLET]: {
      width: '85%',
      maxWidth: 'unset',
      margin: [0, 0, 50],
    },
    [MQ.TABLET_LARGE]: {
      width: '80%',
    },
    [MQ.DESKTOP]: {
      margin: [0, 0, 75],
    },
  },
})
