import { createUseStyles } from 'react-jss'

import { COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    background: COLOR.WHITE,
    color: COLOR.BLUE,
    textAlign: 'center',
    padding: [70, 0],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [MQ.DESKTOP]: {
      padding: [120, 0],
    },
  },
  container: {
    width: '87%',
    margin: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 1140,

    [MQ.TABLET]: {
      width: '82%',
    },
    [MQ.DESKTOP]: {
      width: '73%',
    },
  },
  title: {
    width: '70%',
    margin: 'auto',
    [MQ.TABLET]: {
      width: '45%',
    },
  },
  svgWrapper: {
    width: '40%',
    margin: [50, '5%', 0],
    [MQ.TABLET]: {
      width: '20%',
      margin: [50, '2.5%', 0],
    },
  },
  buttonWrapper: {
    margin: [35, 0, 0],
    [MQ.DESKTOP]: {
      margin: [65, 0, 0],
    },
  },
})
