import React from 'react'

export const donate = () => {
  return (
    <svg
      width="41"
      height="60"
      viewBox="0 0 41 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8114 59.9999C26.1338 59.9987 31.2378 57.8835 35.0009 54.1196C38.764 50.3556 40.878 45.2511 40.878 39.9287H0.740234C0.740234 45.2519 2.85487 50.3571 6.61894 54.1212C10.383 57.8852 15.4882 59.9999 20.8114 59.9999Z"
        fill="#6EC574"
      />
      <path
        d="M20.8114 37.9401C25.2529 37.9401 28.8534 34.3396 28.8534 29.898C28.8534 25.4565 25.2529 21.856 20.8114 21.856C16.3698 21.856 12.7693 25.4565 12.7693 29.898C12.7693 34.3396 16.3698 37.9401 20.8114 37.9401Z"
        fill="#277AAD"
      />
      <path
        d="M2.6748 0.681152H38.9482L20.8115 18.8178L2.6748 0.681152Z"
        stroke="#D499DD"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
