import cn from 'classnames'
import { CONTENTFUL_COLOR, COLOR } from '@constants'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  blueText: {
    color: COLOR.BLUE,
  },
  redText: {
    color: COLOR.RED,
  },
  greenText: {
    color: COLOR.GREEN,
  },
  yellowText: {
    color: COLOR.YELLOW,
  },
  purpleText: {
    color: COLOR.PURPLE,
  },
  blackText: {
    color: COLOR.BLACK,
  },
})

export const useContentfulTextColorPicker = (color) => {
  const classes = useStyles()
  const titleClasses = cn({
    [classes.blueText]: color === CONTENTFUL_COLOR.BLUE,
    [classes.redText]: color === CONTENTFUL_COLOR.RED,
    [classes.greenText]: color === CONTENTFUL_COLOR.GREEN,
    [classes.purpleText]: color === CONTENTFUL_COLOR.PURPLE,
    [classes.yellowText]: color === CONTENTFUL_COLOR.YELLOW,
    [classes.blackText]: color === CONTENTFUL_COLOR.BLACK,
  })

  return titleClasses
}
