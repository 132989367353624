import * as React from 'react'
import { Link } from 'gatsby'

import { useStyles } from './styles'
import { Layout } from '@organisms'
import { Text } from '@atoms'
import { ROUTES } from '@constants'

export const ThankYouView = () => {
  const classes = useStyles()

  return (
    <Layout>
      <div className={classes.wrapper}>
        <div>
          <Text className={classes.title} size="header2" tag="p">
            Thank you for your donation!
          </Text>
          <Text tag="p" className={classes.subtitle}>
            We will be sending you an email receipt shortly.
          </Text>
          <Link className={classes.backButton} to={ROUTES.HOME}>
            <Text size="button">Go back</Text>
          </Link>
        </div>
      </div>
    </Layout>
  )
}
