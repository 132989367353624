import * as React from 'react'
import { isNilOrEmpty, dotPath } from 'ramdasauce'
import { Link } from 'gatsby'
import cn from 'classnames'

import { useStyles } from './styles'
import { ContentfulButton, Svg, Text } from '@atoms'
import { formatDateUrl } from '@helpers'

export const BlogArrows = ({ data }) => {
  const classes = useStyles()

  const button = dotPath('button', data)
  const next = dotPath('next', data)
  const prev = dotPath('prev', data)

  const nextUrl = `/${formatDateUrl(dotPath('publishDate', next))}/${dotPath(
    'urlSlug',
    next
  )}`
  const prevUrl = `/${formatDateUrl(dotPath('publishDate', prev))}/${dotPath(
    'urlSlug',
    prev
  )}`

  return (
    <div className={classes.wrapper}>
      {!isNilOrEmpty(button) && <ContentfulButton data={button} dark />}
      <div className={classes.arrowWrapper}>
        <div className={classes.linkWrapper}>
          {!isNilOrEmpty(prev) && (
            <Link className={cn(classes.link, classes.prev)} to={prevUrl}>
              <div className={classes.svgPrev}>
                <Svg name="arrow" />
              </div>
              <Text tag="p" size="subhead2">
                PREVIOUS POST
              </Text>
            </Link>
          )}
        </div>
        <div className={classes.linkWrapper}>
          {!isNilOrEmpty(next) && (
            <Link className={classes.link} to={nextUrl}>
              <div className={classes.svgNext}>
                <Svg name="arrow" />
              </div>
              <Text tag="p" size="subhead2">
                NEXT POST
              </Text>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}
