import React from 'react'
import { isNilOrEmpty } from 'ramdasauce'

import { Main, MasterContainer, FlashMessage } from '@atoms'
import { Header } from '@molecules'
import { Footer } from '@organisms'

export const Layout = ({ children, flashData }) => {
  return (
    <MasterContainer>
      {!isNilOrEmpty(flashData) && <FlashMessage data={flashData} />}
      <Header />
      <Main>{children}</Main>
      <Footer />
    </MasterContainer>
  )
}
