import * as React from 'react'
import { isNilOrEmpty } from 'ramdasauce'

import { useStyles } from './styles'
import { PhotoItem } from '@molecules'
import { Animated } from '@atoms'

export const PhotoVideoGrid = ({ data }) => {
  const classes = useStyles()

  const gridData = data.gridLayout
  const renderGrid =
    !isNilOrEmpty(gridData) &&
    gridData.map((item, i) => {
      return (
        <div key={item.id}>
          <PhotoItem {...item} />
        </div>
      )
    })

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <Animated type="fade-up-modal">{renderGrid}</Animated>
      </div>
    </div>
  )
}
