import { createUseStyles } from 'react-jss'

import { COLOR } from '@constants'

export const useStyles = createUseStyles({
  textWrapper: {
    display: 'block',
  },
  link: {
    color: COLOR.BLACK,
    '&:hover': {
      color: COLOR.GREY_2,
    },
  },
})
