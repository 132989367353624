import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

export const Seo = ({ lang, meta, title, pageDesc, pageImage }) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const contentfulData = data.contentfulSeoContent
        const roughSeoImage = getSrc(contentfulData.seoImage)
        const formattedSeoImage = 'https:' + roughSeoImage
        const seoImage = pageImage || formattedSeoImage
        const metaDescription = pageDesc || contentfulData.description
        // schema.org example
        // const schemaOrganization = {
        //   '@context': 'https://schema.org',
        //   '@type': 'NGO',
        //   name: 'FISH of Vancouver',
        //   alternateName: 'FISH',
        //   url: 'https://fishvancouver.org',
        //   logo:
        //     'https://fishvancouver.org/static/235a22042082913240e4a06db67882a4/a2124/logo.webp',
        //   sameAs: 'https://www.facebook.com/fishvancouver/',
        //   nonprofitStatus: 'Nonprofit501c3',
        //   address: {
        //     '@type': 'PostalAddress',
        //     addressLocality: 'Vancouver',
        //     addressRegion: 'WA',
        //     postalCode: '98660',
        //     streetAddress: '906 Harney St',
        //   },
        // }

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: 'twitter:card',
                content: 'summary_large_image',
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },

              {
                property: 'og:image',
                content: seoImage,
              },
              {
                property: 'og:image:width',
                content: 1200,
              },
              {
                property: 'og:image:height',
                content: 630,
              },
            ].concat(meta)}
          >
            {/* <script type="application/ld+json">
              {JSON.stringify(schemaOrganization)}
            </script> */}
          </Helmet>
        )
      }}
    />
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    contentfulSeoContent {
      description
      seoImage {
        gatsbyImageData
      }
    }
  }
`
