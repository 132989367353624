import * as React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { isNilOrEmpty } from 'ramdasauce'

import { SVGS } from './js/constants'
import { useStyles } from './styles'

// get data object connected to the "name" prop of the component
// object: {RATIO (percentage of h:w), SVG (svg react component)}
const getSvg = (name) => {
  const key = name.replace(/-/g, '_').toUpperCase()
  return SVGS[key]
}

export const Svg = ({ svgFill, pathFill, rectFill, name, className }) => {
  const classes = useStyles({
    svgFill,
    pathFill,
    rectFill,
  })
  const SVG_DATA = getSvg(name)

  const svgClasses = cn({
    [classes.element]: true,
    [className]: !isNilOrEmpty(className),
  })

  return <>{SVG_DATA && <div className={svgClasses}>{SVG_DATA.SVG}</div>}</>
}

Svg.propTypes = {
  className: PropTypes.string,
}

Svg.defaultProps = {
  className: '',
}
