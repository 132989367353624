import { createUseStyles } from 'react-jss'

import { COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    color: COLOR.BLACK,
  },
  title: {
    margin: [30, 0],
    textTransform: 'uppercase',
    [MQ.TABLET]: {
      margin: [30, 0],
    },
    [MQ.DESKTOP]: {
      margin: [50, 0],
    },
  },
})
