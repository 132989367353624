import * as React from 'react'
import { dotPath, isNilOrEmpty, isUndefined } from 'ramdasauce'
import { getSrc } from 'gatsby-plugin-image'

import { Seo, BlogHero } from '@atoms'
import { BlogArrows, BlogBody } from '@molecules'
import { Layout, PhotoTextGrid } from '@organisms'

export const BlogView = (props) => {
  const data = dotPath('props.data.contentfulBlogPost', props)

  const title = dotPath('title', data)
  const publishDate = dotPath('publishDate', data)
  const heroImage = dotPath('heroImage', data)
  const seoImageSrc = getSrc(heroImage)
  const formattedSeoImage = !isUndefined(seoImageSrc) && 'https:' + seoImageSrc
  const body = dotPath('articleBody', data)
  const button = dotPath('articleCtaButton', data)
  const next = dotPath('nextArticle', data)
  const prev = dotPath('previousArticle', data)
  const gridTitle = dotPath('topPicksTitle', data)
  const grid = dotPath('topPicks', data)
  const tag = dotPath('tag', data)

  const formatGridLinks =
    !isNilOrEmpty(grid) &&
    grid.map((item) => {
      const formattedLinks = {
        text: dotPath('linkText', item),
        buttonType: [
          {
            id: item.id,
            __typename: 'ContentfulExternalLink',
            externalLinkUrl: { externalLinkUrl: dotPath('link.link', item) },
          },
        ],
      }
      return { ...item, ctaButton: formattedLinks }
    })

  const flashData = dotPath('props.data.contentfulFlashMessage', props)

  const bodyData = { body: body, heroImage: heroImage }
  const heroData = { title: title, publishDate: publishDate, tag: tag }
  const arrowData = { button: button, next: next, prev: prev }
  const gridData = { gridLayout: formatGridLinks, title: gridTitle }

  return (
    <Layout flashData={flashData}>
      <Seo title={title} pageImage={formattedSeoImage} />
      <BlogHero data={heroData} />
      <BlogBody data={bodyData} />
      <BlogArrows data={arrowData} />
      {!isNilOrEmpty(grid) && <PhotoTextGrid data={gridData} blogPost />}
    </Layout>
  )
}
