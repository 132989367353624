import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { isNilOrEmpty, dotPath } from 'ramdasauce'

import { useStyles } from './styles'
import { RichText } from '@atoms'

export const BlogBody = ({ data }) => {
  const classes = useStyles()

  const image = dotPath('heroImage', data)
  const body = dotPath('body', data)

  return (
    <div className={classes.wrapper}>
      {!isNilOrEmpty(image) && (
        <GatsbyImage alt={image.title} image={getImage(image)} />
      )}
      {!isNilOrEmpty(body) && (
        <div className={classes.bodyWrapper}>
          <RichText data={body} />
        </div>
      )}
    </div>
  )
}
