export const FORM = {
  METHOD: 'POST',
  ACTION: 'https://formspree.io/f/xvodnkjk',
  NAME: 'contact-form',
}

const INTEREST_OPTIONS = [
  { VALUE: 'Performance', LABEL: 'Performance' },

  { VALUE: 'Volunteer', LABEL: 'Volunteer' },
  { VALUE: 'Join the team', LABEL: 'Join the team' },
  { VALUE: 'Events', LABEL: 'Events' },
  {
    VALUE: 'Partnership',
    LABEL: 'Partnership',
  },
  { VALUE: 'Other', LABEL: 'Other' },
]

const REFERENCE_OPTIONS = [
  {
    VALUE: 'Instagram',
    LABEL: 'Instagram',
  },
  {
    VALUE: 'Facebook',
    LABEL: 'Facebook',
  },
  {
    VALUE: 'Newspaper',
    LABEL: 'Newspaper',
  },
  {
    VALUE: 'Friend or Family',
    LABEL: 'Friend or Family',
  },
  {
    VALUE: 'Search Engine',
    LABEL: 'Search Engine',
  },
  {
    VALUE: 'Other',
    LABEL: 'Other',
  },
]

export const FIELDS = {
  FULL_NAME: {
    NAME: 'full_name',
    LABEL: 'Full Name',
    VALIDATIONS: {
      required: 'Full Name is required.*',
    },
  },
  EMAIL: {
    NAME: 'email',
    LABEL: 'Email Address',
    VALIDATIONS: {
      required: 'Email Address is required.*',
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Must be a valid email.',
      },
    },
  },
  PHONE: {
    NAME: 'phone',
    LABEL: 'Phone',
    VALIDATIONS: {},
  },
  COMPANY: {
    NAME: 'company',
    LABEL: 'Company',
    VALIDATIONS: {},
  },
  INTEREST: {
    NAME: 'interest',
    LABEL: 'Select one',
    OPTIONS: INTEREST_OPTIONS,
    VALIDATIONS: {},
  },
  REFERENCE: {
    NAME: 'reference',
    LABEL: 'Select one',
    OPTIONS: REFERENCE_OPTIONS,
    VALIDATIONS: {},
  },
  MESSAGE: {
    NAME: 'message',
    LABEL: 'How can we help you?',
    VALIDATIONS: {
      required: 'Message is required*',
    },
  },
}
