import * as React from 'react'
import { isNilOrEmpty, dotPath } from 'ramdasauce'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { format } from 'date-fns'

import { useStyles } from './styles'
import { Text } from '@atoms'

export const NewsTile = ({ data }) => {
  const classes = useStyles()

  const title = dotPath('title', data)
  const agency = dotPath('newsAgency', data)
  const link = dotPath('articleLink', data)
  const agencyLink = dotPath('newsAgencyLink', data)
  const image = dotPath('image', data)
  const datePublishedUgly = dotPath('datePublished', data)
  const formattedDate = format(new Date(datePublishedUgly), 'MMMM, d yyyy')

  return (
    <div className={classes.wrapper}>
      {!isNilOrEmpty(agency) && (
        <a
          href={agencyLink}
          target="_blank"
          rel="noreferrer"
          className={classes.agency}
        >
          <Text>{agency}</Text>
        </a>
      )}
      {!isNilOrEmpty(image) && (
        <GatsbyImage
          image={getImage(image)}
          alt={title}
          className={classes.image}
        />
      )}
      {!isNilOrEmpty(formattedDate) && (
        <Text tag="p" size="subhead4" className={classes.date}>
          {formattedDate}
        </Text>
      )}
      {!isNilOrEmpty(title) && (
        <Text tag="p" size="body3" className={classes.title}>
          {title}
        </Text>
      )}
      {!isNilOrEmpty(link) && (
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className={classes.ctaLink}
        >
          <Text>Read More</Text>
        </a>
      )}
    </div>
  )
}
