import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { isNilOrEmpty, dotPath } from 'ramdasauce'
import cn from 'classnames'

import { useStyles } from './styles'
import { Text, Svg } from '@atoms'
import { ROUTES } from '@constants'

export const Footer = () => {
  const classes = useStyles()

  const data = useStaticQuery(footerQuery)
  const contactData = data.contentfulFooter
  const navData = contactData.navigation

  const socialData = [
    {
      link: dotPath('instagramPageLink', contactData),
      svg: 'instagram',
      linkName: 'Instagram',
    },
    {
      link: dotPath('linkedInPageLink', contactData),
      svg: 'linked-in',
      linkName: 'LinkedIn',
    },
    {
      link: `mailto:${dotPath('contactEmail', contactData)}`,
      svg: 'email',
      linkName: 'E-mail',
    },
  ]

  // removes parenthesis & spaces to work as a tel: link
  const formattedPhone = contactData.phoneNumber
    .replace(/[()]/g, '')
    .replace(' ', '-')

  const renderNavigation = navData.map((item, i) => {
    const isNavNameEmpty = isNilOrEmpty(item.urlSlug)
    const link = isNavNameEmpty ? '' : item.urlSlug
    return (
      !isNilOrEmpty(item.title) && (
        <Link to={`/${link}`} key={i} className={classes.link}>
          <Text size="subhead2" className={classes.linkText}>
            {item.title}
          </Text>
        </Link>
      )
    )
  })

  const renderSocial = socialData.map((item, i) => {
    return (
      <a
        href={item.link}
        className={classes.socialLinks}
        target="_blank"
        rel="noreferrer"
        key={i}
      >
        <Svg name={item.svg} className={classes.socialSvg} />
        <span>{item.linkName}</span>
      </a>
    )
  })

  return (
    <footer className={classes.footerWrapper}>
      <div className={classes.container}>
        <div className={classes.logo}>
          <Link to={ROUTES.HOME} className={classes.homeLink}>
            <StaticImage
              src="../../../assets/images/cftaLogo.png"
              alt="Simple Safety Solutions Logo"
            />
            <span className={classes.hidden}>Home</span>
          </Link>
        </div>
        <div className={classes.textBlock}>
          <div className={cn(classes.navigation, classes.navLinks)}>
            <Text size="subhead2" tag="p" className={classes.header}>
              Navigation
            </Text>
            {renderNavigation}
            <Link to={`/donate`} className={classes.link}>
              <Text size="subhead2" className={classes.linkText}>
                Donate
              </Text>
            </Link>
          </div>
          <div className={classes.location}>
            <Text size="subhead2" tag="p" className={classes.header}>
              CONTACT
            </Text>
            <a href={`tel:${formattedPhone}`} className={classes.contactLink}>
              <Text size="body1">{contactData.phoneNumber}</Text>
            </a>
            <div className={classes.contactLink}>
              <Text size="body1" tag="p" className={classes.mapLink}>
                {contactData.address}
              </Text>
            </div>
            <div className={classes.contactLink}>
              <Text size="body1" tag="p" className={classes.mapLink}>
                {dotPath('contactEmail', contactData)}
              </Text>
            </div>
            <div className={classes.social}>{renderSocial}</div>
          </div>
        </div>
      </div>
    </footer>
  )
}

const footerQuery = graphql`
  query {
    contentfulFooter {
      navigation {
        title
        urlSlug
      }
      phoneNumber
      address
      contactEmail
      linkedInPageLink
      instagramPageLink
    }
  }
`
