import { createUseStyles } from 'react-jss'

import { COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    height: 203,
    width: 203,
    display: 'flex',
    flexDirection: 'column',
    margin: [0, 12],

    [MQ.DESKTOP]: {
      width: 314,
      height: 314,
      margin: [0, 40],
    },
  },
  captionWrapper: {
    backgroundColor: COLOR.RED,
  },
  caption: {
    textTransform: 'uppercase',
    color: COLOR.WHITE,
    padding: [12, 20, 11],
    margin: 0,
    [MQ.DESKTOP]: {
      padding: [19, 25, 18],
    },
  },
  image: {
    filter: 'grayscale(1)',
  },
})
