import * as React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { useStyles } from './styles'
import { Text } from '@atoms'

export const FormButton = ({
  submitting,
  onSubmit,
  submitClasses,
  contact,
}) => {
  const classes = useStyles()

  const loadingClasses = cn({
    [classes.buttonText]: true,
  })

  return (
    <>
      {!submitting && (
        <button
          className={cn(submitClasses, classes.button)}
          disabled={submitting}
          onClick={onSubmit}
        >
          <Text className={classes.buttonText}>
            {contact ? 'Send' : 'Subscribe'}
          </Text>
        </button>
      )}
      {submitting && (
        <div className={cn(submitClasses, classes.button)}>
          <Text className={loadingClasses}>
            {contact ? 'Sending' : 'Subscribing'}
            <span>.</span>
            <span>.</span>
            <span>.</span>
          </Text>
        </div>
      )}
    </>
  )
}

FormButton.propTypes = {
  submitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  submitClasses: PropTypes.string,
}

FormButton.defaultProps = {
  submitting: false,
  onSubmit: null,
  submitClasses: '',
}
