import * as React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { dotPath, isNilOrEmpty } from 'ramdasauce'
import { format } from 'date-fns'
import cn from 'classnames'

import { useStyles } from './styles'
import { Text } from '@atoms'

export const TileItem = ({ data }) => {
  const classes = useStyles()

  const textBlockClasses = cn({
    [classes.textBlock]: true,
  })

  const coverPhoto = dotPath('coverPhoto', data)
  const heroImage = dotPath('heroImage', data)
  const coverPhotoWFallback = !isNilOrEmpty(coverPhoto) ? coverPhoto : heroImage

  const publishDate = dotPath('publishDate', data)
  const publishDt = new Date(publishDate)
  // handles timezone offset issue
  const dtDateOnly = new Date(
    publishDt.valueOf() + publishDt.getTimezoneOffset() * 60 * 1000
  )
  const formattedDate = format(dtDateOnly, 'yyyy/dd/MM')

  return (
    <Link
      className={classes.wrapper}
      to={`/${formattedDate}/${dotPath('urlSlug', data)}/`}
    >
      {!isNilOrEmpty(coverPhotoWFallback) && (
        <GatsbyImage
          image={getImage(coverPhotoWFallback)}
          alt={dotPath('title', data)}
          className={classes.thumbnail}
        />
      )}
      <div className={textBlockClasses}>
        <Text tag="p" size="subhead2" className={classes.title}>
          {dotPath('title', data)}
        </Text>
        <Text tag="h3" size="body1" className={classes.desc}>
          {dotPath('coverTileDescription', data)}
        </Text>
        <Text tag="h3" size="subhead3" className={classes.ctaText}>
          Read More
        </Text>
      </div>
    </Link>
  )
}
