import { createUseStyles } from 'react-jss'

import { COLOR } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    background: COLOR.GREY_1,
    textAlign: 'center',
    padding: [15, 0],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    '& a': {
      color: COLOR.BLACK,
    },
  },

  close: {
    position: 'absolute',
    right: '5%',
    top: '50%',
    transform: 'translateY(-50%)',
    height: 14,
    width: 14,
    border: 'none',
    outline: 'none',
    background: 'transparent',
    padding: 0,
    margin: 0,
  },
  x: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:before': {
      content: "''",
      width: 14,
      height: 1,
      backgroundColor: COLOR.BLACK,
      transform: 'rotate(-45deg)',
      position: 'absolute',
    },
    '&:after': {
      content: "''",
      width: 14,
      height: 1,
      backgroundColor: COLOR.BLACK,
      transform: 'rotate(45deg)',
    },
  },
  link: {
    textDecoration: 'none',
  },
  text: {
    margin: [0, 50],
  },
})
