import React from 'react'

export const logo = () => {
  return (
    <svg
      width="100"
      height="48"
      viewBox="0 0 100 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M99.42 47.0599H80.82L59.76 0.379883H78.36L99.42 47.0599Z"
        fill="white"
      />
      <path
        d="M0.209961 24.0001C0.212611 30.1576 2.65985 36.0621 7.01387 40.4161C11.3679 44.7702 17.2724 47.2174 23.43 47.2201V0.810059C17.2768 0.810053 11.3753 3.25232 7.02154 7.60043C2.66781 11.9485 0.217911 17.8469 0.209961 24.0001Z"
        fill="white"
      />
      <path
        d="M48.4899 22.1601L27.1399 0.810059H48.4899V22.1601Z"
        fill="white"
      />
      <path
        d="M27.1399 47.2499L48.4899 25.8999V47.2499H27.1399Z"
        fill="white"
      />
      <path
        d="M61.6 47.0598C66.7914 47.0598 71 42.8512 71 37.6598C71 32.4683 66.7914 28.2598 61.6 28.2598C56.4085 28.2598 52.2 32.4683 52.2 37.6598C52.2 42.8512 56.4085 47.0598 61.6 47.0598Z"
        fill="white"
      />
    </svg>
  )
}
