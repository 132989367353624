import * as React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { isNilOrEmpty, dotPath } from 'ramdasauce'
import { Link } from 'gatsby'
import cn from 'classnames'

import { useStyles } from './styles'
import { Text, ContentfulButton } from '@atoms'
import { useContentfulTextColorPicker } from '@helpers'

export const CirclePhotoItem = ({
  title,
  paragraph,
  image,
  ctaButton,
  color,
  titleFirst,
  link,
}) => {
  const classes = useStyles()

  const textColor = useContentfulTextColorPicker(color)

  const showTopImage = !isNilOrEmpty(image) && !titleFirst
  const showBottomImage = !isNilOrEmpty(image) && titleFirst

  const LinkWrapper = ({ children, className }) => {
    const linkType = !isNilOrEmpty(link) && link.__typename

    switch (linkType) {
      case 'ContentfulInternalLink': {
        const urlSlug = dotPath('link.urlSlug', link)
        // handles the homepage links since the url slug for that page is null by default
        const url = isNilOrEmpty(urlSlug) ? '' : urlSlug
        return (
          <Link className={cn(classes.link, className)} to={`/${url}`}>
            {children}
          </Link>
        )
      }
      case 'ContentfulExternalLink': {
        return (
          <a
            href={dotPath('externalLinkUrl.externalLinkUrl', link)}
            target="_blank"
            rel="noreferrer"
            className={cn(classes.link, className)}
          >
            {children}
          </a>
        )
      }
      default:
        return <div className={cn(classes.link, className)}>{children}</div>
    }
  }

  return (
    <>
      {showTopImage && (
        <LinkWrapper>
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={image.title}
            className={classes.image}
          />
        </LinkWrapper>
      )}
      <div className={classes.wrapper}>
        {!isNilOrEmpty(title) && (
          <Text tag="h4" size="body3" className={cn(classes.title, textColor)}>
            {title}
          </Text>
        )}
        {showBottomImage && (
          <LinkWrapper className={classes.middleLink}>
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={image.title}
              className={cn(classes.image, classes.middleImage)}
            />
          </LinkWrapper>
        )}
        {!isNilOrEmpty(paragraph) && (
          <Text tag="p" size="body1" className={classes.body}>
            {paragraph.paragraph}
          </Text>
        )}
        {!isNilOrEmpty(ctaButton) && (
          <div className={classes.buttonWrapper}>
            <ContentfulButton data={ctaButton} />
          </div>
        )}
      </div>
    </>
  )
}

CirclePhotoItem.propTypes = {
  text: PropTypes.string,
}

CirclePhotoItem.defaultProps = {
  text: 'CirclePhotoItem',
}
