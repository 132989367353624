import { createUseStyles } from 'react-jss'

import { COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [MQ.TABLET]: {
      padding: [50, 0, 0],
    },
    [MQ.TABLET_LARGE]: {
      padding: 0,
      transform: 'translateY(-50px)',
    },
    [MQ.DESKTOP]: {
      transform: 'translateY(-75px)',
    },
  },
  arrowWrapper: {
    display: 'flex',
    width: '90%',
    justifyContent: 'space-between',
    padding: [50, 0],
    [MQ.TABLET]: {
      padding: [75, 0],
    },
    [MQ.TABLET_LARGE]: {
      padding: [100, 0],
      width: '60%',
    },
  },
  linkWrapper: {
    width: '40%',
    [MQ.TABLET]: {
      width: 'auto',
    },
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    width: '100%',
    color: COLOR.BLACK,
    textDecoration: 'none',
    [MQ.TABLET]: {
      alignItems: 'flex-start',
    },
  },
  prev: {
    alignItems: 'flex-start',
    [MQ.TABLET]: {
      alignItems: 'flex-end',
    },
  },
  svgPrev: {
    height: 50,
    width: 50,
    transform: 'scaleX(-1)',
    [MQ.TABLET]: {
      marginBottom: 10,
      width: 60,
    },
    [MQ.DESKTOP]: {
      marginBottom: 24,
      width: 70,
    },
  },
  svgNext: {
    height: 50,
    width: 50,
    [MQ.TABLET]: {
      marginBottom: 10,
      width: 60,
    },
    [MQ.DESKTOP]: {
      marginBottom: 24,
      width: 70,
    },
  },
})
