import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles({
  element: {
    minWidth: '100%',
    position: 'relative',
    pointerEvents: 'none',
    minHeight: '100%',
    '&::after': {
      content: `''`,
      display: 'block',
    },
    '& svg, & path': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
    },
  },
})
