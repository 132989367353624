import { createUseStyles } from 'react-jss'

import { COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    backgroundColor: COLOR.WHITE,
    color: COLOR.BLACK,
    margin: [80, 0, 70],
    [MQ.TABLET]: {
      margin: [100, 0, 80],
    },
    [MQ.DESKTOP]: {
      margin: [134, 0, 110],
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [MQ.TABLET_LARGE]: {
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: [0, 'min(10%, 156px)'],
      maxWidth: 1560,
      margin: 'auto',
    },
  },
  image: {
    width: '75%',
    padding: [35, 0],
    [MQ.TABLET]: {
      width: '35%',
    },
    [MQ.TABLET_LARGE]: {
      width: '37%',
    },
  },
  grey: {
    backgroundColor: COLOR.GREY_1,
    color: COLOR.BLACK,
    padding: [80, 0, 70],
    [MQ.TABLET]: {
      padding: [100, 0, 80],
    },
    [MQ.DESKTOP]: {
      padding: [134, 0, 110],
    },
  },
  textBlock: {
    width: '75%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    [MQ.TABLET]: {
      width: '62%',
    },
    [MQ.TABLET_LARGE]: {
      width: '52%',
    },
    [MQ.DESKTOP]: {
      width: '70%',
      maxWidth: 873,
    },
  },
  imagePresent: {
    [MQ.TABLET_LARGE]: {
      width: '52.2%',
      margin: 0,
    },
  },
  title: {
    textAlign: 'left',
    [MQ.TABLET]: {
      width: '80%',
    },
    [MQ.DESKTOP]: {
      width: '85%',
    },
  },
  body: {
    padding: [35, 0, 20],
  },
})
