import { FONTS } from '@constants'

export const TYPOGRAPHY = {
  PROXIMA_NOVA: {
    fontFamily: FONTS.PROXIMA_NOVA,
    fontStyle: 'normal',
    fontWeight: 400,
  },
  PROXIMA_NOVA_BOLD: {
    fontFamily: FONTS.PROXIMA_NOVA,
    fontStyle: 'normal',
    fontWeight: 700,
  },
}
