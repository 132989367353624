import { createUseStyles } from 'react-jss'
import { COLOR, TYPOGRAPHY } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    position: 'relative',
    margin: [7, 0, 20],
    backgroundColor: COLOR.GREY_1,
    borderRadius: 5,
  },
  label: {
    ...TYPOGRAPHY.PROXIMA_NOVA,
    fontSize: 18,
    position: 'absolute',
    top: '50%',
    color: COLOR.GREY_2,
    transform: 'translateY(-50%)',
    left: 20,
    zIndex: 50,
    fontWeight: 400,
    letterSpacing: '0.05em',
  },
  error: {
    color: COLOR.RED,
    fontWeight: 400,
  },
  active: {
    display: 'none',
  },
  arrowWrapper: {
    position: 'absolute',
    bottom: 2,
    right: 12,
  },
  arrow: {
    width: '0',
    height: '0',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    borderTop: '12px solid #BDBDBD',
    zIndex: 50,
  },
  arrowActive: {
    transform: 'rotate(45deg)',
  },
  dropdown: {
    backgroundColor: COLOR.GREY_1,
    zIndex: 200,
    position: 'absolute',
    width: '100%',
    top: '100%',
    overflow: 'hidden',
    height: 0,
    transition: 'height 200ms ease-in-out',
  },
  open: {
    height: 200,
    overflowY: 'scroll',
    zIndex: 300,
  },
  radio: {
    display: 'none',
  },
  option: {
    ...TYPOGRAPHY.PROXIMA_NOVA,
    letterSpacing: '0.05em',
    fontWeight: 400,
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    margin: 0,
    width: '100%',
    display: 'inline-block',
    textAlign: 'left',
    color: COLOR.BLACK,
    padding: '14px 22px',
    border: `1px solid ${COLOR.WHITE}`,
    fontSize: 18,
    borderTop: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  selected: {
    backgroundColor: 'transparent',
  },
  button: {
    ...TYPOGRAPHY.PROXIMA_NOVA,
    letterSpacing: '0.05em',
    fontWeight: 400,
    width: '100%',
    backgroundColor: 'transparent',
    position: 'relative',
    zIndex: 100,
    border: 'none',
    fontSize: 18,
    color: COLOR.BLACK,
    outline: 0,
    padding: '16px 18px',
    boxSizing: 'border-box',
    '&:hover': {
      cursor: 'pointer',
    },
  },
})
