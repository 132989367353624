import React from 'react'

export const impact = () => {
  return (
    <svg
      width="50"
      height="61"
      viewBox="0 0 50 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.564453 0.0595703V17.517L44.3818 37.287V19.8295L0.564453 0.0595703Z"
        fill="#D499DD"
      />
      <path
        d="M48.5537 21.6099V39.0673L4.73633 58.8373V41.3798L48.5537 21.6099Z"
        stroke="#D11A1F"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
