import { createUseStyles } from 'react-jss'

import { COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    width: '100%',
    background: COLOR.GREEN,
    color: COLOR.WHITE,
    textAlign: 'center',
  },
  blogTitle: {
    padding: [50, 0, 0],
    textDecoration: 'underline',
    textTransform: 'uppercase',

    [MQ.TABLET_LARGE]: {
      padding: [70, 0, 0],
    },
  },
  title: {
    padding: [30],
    [MQ.TABLET_LARGE]: {
      padding: [40],
    },
  },
  date: {
    textTransform: 'uppercase',
    padding: [0, 0, 50],
    [MQ.TABLET_LARGE]: {
      padding: [0, 0, 150],
    },
    [MQ.DESKTOP]: {
      padding: [0, 0, 185],
    },
  },
})
