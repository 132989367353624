import * as React from 'react'
import PropTypes from 'prop-types'
import { useStyles } from './styles'
import { isNilOrEmpty } from 'ramdasauce'
import cn from 'classnames'

export const InputText = ({
  name,
  label,
  type,
  register,
  validations,
  className,
  currentValue,
  error,
  errorClass,
  onBlur,
  labelClassName,
  inputClassName,
}) => {
  const classes = useStyles()

  const inputClasses = cn({
    [classes.input]: true,
    [inputClassName]: !isNilOrEmpty(inputClassName),
    [errorClass]: !isNilOrEmpty(errorClass) && error,
  })

  const wrapperClasses = cn({
    [classes.wrapper]: true,
    [className]: !isNilOrEmpty(className),
  })

  const labelClasses = cn({
    [classes.label]: true,
    [labelClassName]: !isNilOrEmpty(labelClassName),
    [classes.hidden]: !isNilOrEmpty(currentValue),
    [errorClass]: !isNilOrEmpty(errorClass) && error,
  })

  return (
    <div className={wrapperClasses}>
      <label htmlFor={name} className={labelClasses}>
        {label}
      </label>

      <input
        type={type}
        // name={name}
        className={inputClasses}
        onBlur={onBlur}
        id={name}
        {...register(name, validations)}
      />
    </div>
  )
}

InputText.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  register: PropTypes.func.isRequired,
  className: PropTypes.string,
  currentValue: PropTypes.string.isRequired,
  error: PropTypes.any,
  errorClass: PropTypes.string,
  onBlur: PropTypes.func,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
}

InputText.defaultProps = {
  type: 'text',
  className: '',
  currentValue: '',
  error: false,
  errorClass: '',
  onBlur: undefined,
  labelClassName: undefined,
  inputClassName: undefined,
}
