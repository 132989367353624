import * as files from '../svgs'

// Calculate SVGS.RATIO //
// Percentage of svg h:w
// height: Int, width: Int
// You can find the h & w in the svg `viewbox` attr
const getRatio = (height, width) => {
  return `${(height / width) * 100}%`
}

export const SVGS = {
  // LOGO: {
  //   RATIO: getRatio(1, 1),
  //   SVG: files.logo(),
  // },
  ABOUT_HOVER: { RATIO: getRatio(1, 1), SVG: files.aboutHover() },
  ABOUT: { RATIO: getRatio(1, 1), SVG: files.about() },
  ARROW: { RATIO: getRatio(1, 1), SVG: files.arrow() },
  BLOG_HOVER: { RATIO: getRatio(1, 1), SVG: files.blogHover() },
  BLOG: { RATIO: getRatio(1, 1), SVG: files.blog() },
  CONTACT_HOVER: { RATIO: getRatio(1, 1), SVG: files.contactHover() },
  CONTACT: { RATIO: getRatio(1, 1), SVG: files.contact() },
  DONATE_HOVER: { RATIO: getRatio(1, 1), SVG: files.donateHover() },
  DONATE: { RATIO: getRatio(1, 1), SVG: files.donate() },
  EMAIL: { RATIO: getRatio(1, 1), SVG: files.email() },
  IMPACT_HOVER: { RATIO: getRatio(1, 1), SVG: files.impactHover() },
  IMPACT: { RATIO: getRatio(1, 1), SVG: files.impact() },
  INSTAGRAM: { RATIO: getRatio(1, 1), SVG: files.instagram() },
  LINKED_IN: { RATIO: getRatio(1, 1), SVG: files.linkedIn() },
  LOGO: { RATIO: getRatio(1, 1), SVG: files.logo() },
  VISION_HOVER: { RATIO: getRatio(1, 1), SVG: files.visionHover() },
  VISION: { RATIO: getRatio(1, 1), SVG: files.vision() },
}
