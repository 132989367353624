import { createUseStyles } from 'react-jss'

import { COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  '@keyframes blink': {
    '0%': {
      opacity: 0.2,
    },
    '20%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0.2,
    },
  },
  button: {
    borderRadius: 118,
    border: 'none',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  buttonText: {
    padding: [20, 33, 17],
    textDecoration: 'none',
    display: 'inline-block',
    color: COLOR.WHITE,
    [MQ.TABLET]: {
      padding: [22, 33, 18],
    },
    '& span': {
      animation: '$blink 1.4s ease infinite',
    },
    '& span:nth-child(2)': {
      animationDelay: '0.2s',
    },
    '& span:nth-child(3)': {
      animationDelay: '0.4s',
    },
  },
  submitting: {
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      animation: '$blink 1.4s ease infinite',
    },
    '& span:nth-child(2)': {
      animationDelay: '0.2s',
    },
    '& span:nth-child(3)': {
      animationDelay: '0.4s',
    },
  },
  submitted: {
    margin: 'auto',
    background: 'transparent',
    width: '100%',
    cursor: 'default',
  },
  buttonError: {
    backgroundColor: COLOR.GREY_2,
  },
})
