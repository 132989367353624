import React from 'react'

export const about = () => {
  return (
    <svg
      width="61"
      height="59"
      viewBox="0 0 61 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.6996 16.5643C57.2737 16.5643 60.9818 12.8563 60.9818 8.28215C60.9818 3.70804 57.2737 0 52.6996 0C48.1255 0 44.4175 3.70804 44.4175 8.28215C44.4175 12.8563 48.1255 16.5643 52.6996 16.5643Z"
        fill="#D499DD"
      />
      <path
        d="M12.8179 58.0356L52.6994 18.1494V58.0356H12.8179Z"
        fill="#6EC574"
      />
      <path
        d="M1.68311 11.9814V27.6478L41.0128 45.3953V29.7242L1.68311 11.9814Z"
        stroke="#277AAD"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
