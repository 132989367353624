import { createUseStyles } from 'react-jss'
import { Z_INDEX, TYPOGRAPHY, COLOR } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundColor: COLOR.GREY_1,
    borderRadius: '5px',
    margin: [7, 0, 0],
  },
  input: {
    padding: '19px 16px',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    outline: 'none',
    ...TYPOGRAPHY.PROXIMA_NOVA,
    fontWeight: 400,
    position: 'relative',
    fontSize: 18,
    backgroundColor: 'transparent',
    zIndex: Z_INDEX.INPUT,
    textAlign: 'left',
    WebkitBorderRadius: 0,
    resize: 'none',
    border: 'none',
    letterSpacing: '0.05em',
  },
  label: {
    position: 'absolute',
    ...TYPOGRAPHY.PROXIMA_NOVA,
    fontWeight: 400,
    color: COLOR.GREY_2,
    fontSize: 18,
    textAlign: 'left',
    paddingRight: 16,
    left: 18,
    top: 19,
    zIndex: Z_INDEX.INPUT_LABEL,
    letterSpacing: '0.05em',
  },
  hidden: {
    display: 'none',
  },
})
