import { createUseStyles } from 'react-jss'

import { COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    textDecoration: 'none',
  },
  thumbnail: {
    filter: 'grayscale(1)',
  },
  textBlock: {
    backgroundColor: COLOR.WHITE,
    color: COLOR.BLACK,
    textDecoration: 'none',
    width: '90%',
    margin: 'auto',
    padding: [15],
    transform: 'translateY(-26px)',
    position: 'relative',
    display: 'block',
    '&:after': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: 3,
      margin: 'auto',
      bottom: 0,
      left: 0,
      backgroundColor: COLOR.GREEN,
    },

    [MQ.TABLET]: {
      padding: [22],
      width: '85%',
    },
    [MQ.DESKTOP]: {
      padding: [30],
      width: '78%',
    },
  },
  desc: {
    padding: [13, 0, 15],
  },
  title: {
    textTransform: 'uppercase',
  },
  altBlock: {
    backgroundColor: COLOR.WHITE,
  },
  ctaText: {
    color: COLOR.GREY_2,
    textTransform: 'uppercase',
  },
})
