import { createUseStyles } from 'react-jss'

import { COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    [MQ.DESKTOP]: {
      minHeight: '43vw',
      justifyContent: 'flex-end',
    },
  },
  imageWrapper: {
    height: '100%',
    width: '100%',
    [MQ.TABLET]: {
      position: 'absolute',
      width: '90%',
      clipPath: 'polygon(0 0, 100% 0, 50% 100%, 0% 100%)',
    },
    [MQ.TABLET_LARGE]: {
      width: '80%',
      clipPath: 'polygon(0 0, 100% 0, 70% 100%, 0% 100%)',
    },
    [MQ.DESKTOP]: {
      width: '67.78%',
    },
  },
  image: {
    height: '100%',
    filter: 'grayscale(1)',
  },
  textBlock: {
    position: 'relative',
    color: COLOR.WHITE,
    padding: [40],
    width: '100%',
    [MQ.TABLET]: {
      maxWidth: 550,
      width: '75%',
      padding: [60],
      alignSelf: 'flex-end',
      margin: [100, '13%', 0, 0],
    },
    [MQ.TABLET_LARGE]: {
      width: '50%',
      maxWidth: 'unset',
      padding: [80],
    },
    [MQ.DESKTOP]: {
      minHeight: 520,
      margin: [120, '13%', 0, 0],
    },
    '& h1': {
      margin: [0, 0, 20],
      [MQ.TABLET_LARGE]: {
        margin: [0, 0, 30],
      },
      [MQ.DESKTOP]: {
        margin: [0, 0, 40],
      },
    },
  },
  donate: {
    [MQ.TABLET_LARGE]: {
      width: '55%',
      padding: [70, 80, 30, 80],
    },
  },
  blue: {
    background: COLOR.BLUE,
  },
  red: {
    background: COLOR.RED,
  },
  green: {
    background: COLOR.GREEN,
  },
  yellow: {
    background: COLOR.YELLOW,
  },
  purple: {
    background: COLOR.PURPLE,
  },
  black: {
    background: COLOR.BLACK,
  },
})
