export const PALETTE = {
  BLACK: '#000',
  WHITE: '#ffffff',
  DARKER_BLUE: '#266097',
  NAVY: '#0D1C3F',
  GREY_1: '#EFEFEF',
  GREY_2: '#C6C6C6',
  BLUE: '#277AAD',
  GREEN: '#6EC574',
  YELLOW: '#E9A73E',
  RED: '#D11A1F',
  PURPLE: '#D499DD',
}

export const CONTENTFUL_COLOR = {
  RED: 'Red',
  BLUE: 'Blue',
  GREEN: 'Green',
  PURPLE: 'Purple',
  YELLOW: 'Yellow',
  BLACK: 'Black',
}

export const COLOR = {
  BASE_ACCENT: PALETTE.PURPLE,
  BASE_BACKGROUND: PALETTE.WHITE,
  BASE_FONT: PALETTE.BLACK,
  LINK_FONT: PALETTE.BLACK,
  ...PALETTE,
}
