import React from 'react'

export const blog = () => {
  return (
    <svg
      width="61"
      height="67"
      viewBox="0 0 61 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2189 35.616C28.9295 35.616 36.8016 27.744 36.8016 18.0333C36.8016 8.32271 28.9295 0.450684 19.2189 0.450684C9.50826 0.450684 1.63623 8.32271 1.63623 18.0333C1.63623 27.744 9.50826 35.616 19.2189 35.616Z"
        fill="#D11A1F"
      />
      <path
        d="M1.63623 46.8153L24.5487 23.9028H1.63623V46.8153Z"
        stroke="#E9A73E"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
      <path
        d="M42.5065 53.7176C52.2171 53.7176 60.0892 45.8455 60.0892 36.1349C60.0892 26.4243 52.2171 18.5522 42.5065 18.5522C32.7959 18.5522 24.9238 26.4243 24.9238 36.1349C24.9238 45.8455 32.7959 53.7176 42.5065 53.7176Z"
        fill="#6EC574"
      />
      <path
        d="M60.0943 64.912L37.1819 41.9995H60.0943V64.912Z"
        stroke="#277AAD"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
