import React from 'react'

export const aboutHover = () => {
  return (
    <svg
      width="61"
      height="59"
      viewBox="0 0 61 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.891 16.5882C56.4651 16.5882 60.1732 12.8802 60.1732 8.30608C60.1732 3.73197 56.4651 0.0239258 51.891 0.0239258C47.3169 0.0239258 43.6089 3.73197 43.6089 8.30608C43.6089 12.8802 47.3169 16.5882 51.891 16.5882Z"
        fill="white"
      />
      <path
        d="M40.8121 29.2521L40.9056 29.2941V46.5038L28.9524 41.1118L12.0093 58.0595H51.8908V18.1733L40.8121 29.2521Z"
        fill="white"
      />
      <path
        d="M39.5029 44.3341V30.5664L30.0142 40.0504L39.5029 44.3341Z"
        fill="white"
      />
      <path
        d="M1.57606 27.2226V13.0901L39.5028 30.2016V30.5663L40.8122 29.2522L0.173096 10.9155V28.1252L28.9525 41.1119L30.0141 40.0504L1.57606 27.2226Z"
        fill="white"
      />
    </svg>
  )
}
