import * as React from 'react'

export const instagram = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.491 2.56834C4.79341 2.56834 2.56834 4.80011 2.56834 7.60073V20.2339C2.56834 23.0345 4.79341 25.2662 7.491 25.2662H19.9047C22.6022 25.2662 24.8273 23.0345 24.8273 20.2339V7.60073C24.8273 4.80011 22.6022 2.56834 19.9047 2.56834H7.491ZM0 7.60073C0 3.42427 3.33271 0 7.491 0H19.9047C24.0629 0 27.3957 3.42427 27.3957 7.60073V20.2339C27.3957 24.4103 24.0629 27.8346 19.9047 27.8346H7.491C3.33271 27.8346 0 24.4103 0 20.2339V7.60073Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2874 10.1339C13.5266 10.0191 12.7487 10.151 12.0634 10.5129C11.3777 10.8749 10.8176 11.4501 10.4664 12.1601C10.1151 12.8704 9.99215 13.6773 10.1167 14.4649C10.2412 15.2525 10.6061 15.9767 11.1555 16.5358C11.7046 17.0946 12.4098 17.4601 13.1693 17.5845C13.9288 17.7088 14.7082 17.5867 15.3976 17.2335C16.0875 16.8801 16.6544 16.3122 17.0141 15.6068C17.3739 14.901 17.5067 14.0959 17.3917 13.3066C17.2744 12.5017 16.9062 11.7599 16.3458 11.1896C15.7857 10.6196 15.0636 10.251 14.2874 10.1339ZM10.8641 8.24175C12.0329 7.62455 13.3652 7.39731 14.6706 7.59431C16.0021 7.79524 17.2312 8.42618 18.1778 9.38946C19.124 10.3524 19.7379 11.5963 19.9332 12.9363C20.1246 14.2499 19.9044 15.5922 19.3022 16.7734C18.6998 17.9548 17.7445 18.917 16.5685 19.5194C15.3922 20.1219 14.0572 20.3324 12.7543 20.1191C11.4515 19.9057 10.2515 19.2803 9.3236 18.3359C8.39596 17.3919 7.78719 16.1773 7.57986 14.8661C7.37255 13.555 7.57647 12.21 8.16427 11.0215C8.75221 9.83275 9.69563 8.85875 10.8641 8.24175Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8145 6.96931C18.8145 6.02367 19.581 5.25708 20.5267 5.25708H20.5384C21.4841 5.25708 22.2507 6.02367 22.2507 6.96931C22.2507 7.91495 21.4841 8.68154 20.5384 8.68154H20.5267C19.581 8.68154 18.8145 7.91495 18.8145 6.96931Z"
      />
    </svg>
  )
}
