import React from 'react'

export const vision = () => {
  return (
    <svg
      width="61"
      height="53"
      viewBox="0 0 61 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.2044 52.5772C40.414 52.5772 48.6905 44.3007 48.6905 34.0911C48.6905 23.8815 40.414 15.605 30.2044 15.605C19.9948 15.605 11.7183 23.8815 11.7183 34.0911C11.7183 44.3007 19.9948 52.5772 30.2044 52.5772Z"
        fill="#E9A73E"
      />
      <path
        d="M2.52002 1.7627H57.8953L30.2045 29.4471L2.52002 1.7627Z"
        stroke="#277AAD"
        strokeWidth="3"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
