import React, { useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { isNilOrEmpty, dotPath } from 'ramdasauce'
import { useForm } from 'react-hook-form'
import cn from 'classnames'

import { useStyles } from './styles'
import { Text } from '@atoms'
import { PRODUCTS } from '@constants'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY)

const AMOUNT = {
  NAME: 'amount',
  LABEL: 'Amount',
  VALIDATIONS: {
    required: 'Please enter a valid amount',
  },
}

export const DonationForm = ({ data }) => {
  const classes = useStyles()
  const [frequency, setFrequency] = useState('single')
  const [loading, setLoading] = useState(false)

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm()

  const giveText = dotPath('giveText', data)
  const donateButtonText = dotPath('donateButtonText', data)
  const legalNonProfitText = dotPath(
    'legalNonProfitText.legalNonProfitText',
    data
  )

  const handleSubmitClick = async (data) => {
    const stripe = await stripePromise

    const productSku =
      frequency === 'month' ? PRODUCTS.MONTHLY : PRODUCTS.SINGLE

    if (data.amount <= 4.99) {
      setError(AMOUNT.NAME, {
        type: 'manual',
        message: 'Must be a value of $5 or more',
      })
      return
    } else {
      setLoading(true)

      fetch(`/api/custom-amount`, {
        method: `POST`,
        body: JSON.stringify({
          frequency: frequency,
          amount: data.amount * 100,
          product: productSku,
        }),
      })
        .then((res) => {
          if (res.ok) {
            return res.json()
          } else {
            // setError(true)
            throw new Error('Something went wrong')
          }
        })
        .then(async (res) => {
          const mode = res.type === 'recurring' ? 'subscription' : 'payment'
          const { error } = await stripe.redirectToCheckout({
            lineItems: [{ price: res.id, quantity: 1 }],
            mode: mode,
            successUrl: `${window.location.origin}/thank-you`,
            cancelUrl: `${window.location.origin}/donate`,
            billingAddressCollection: 'required',
          })
          setLoading(false)
          if (error) {
            // redirect to /donate
            return
          }
        })
        .catch((error) => {
          console.log('Error:', error)
        })
    }
  }
  const oneTimeClasses = cn({
    [classes.button]: true,
    [classes.oneTime]: true,
    [classes.active]: frequency === 'single',
  })
  const monthlyClasses = cn({
    [classes.button]: true,
    [classes.monthly]: true,
    [classes.active]: frequency === 'month',
  })
  const submitClasses = cn({
    [classes.submit]: true,
    [classes.buttonError]: !isNilOrEmpty(errors),
  })
  const inputClasses = cn({
    [classes.input]: true,
    [classes.error]: !isNilOrEmpty(errors),
  })
  const labelClasses = cn({
    [classes.label]: true,
  })

  return (
    <div className={classes.wrapper}>
      <button className={oneTimeClasses} onClick={() => setFrequency('single')}>
        <Text>Give Once</Text>
      </button>
      <button className={monthlyClasses} onClick={() => setFrequency('month')}>
        <Text>Monthly</Text>
      </button>
      {!isNilOrEmpty(giveText) && (
        <Text size="body3" tag="p" className={classes.give}>
          {giveText}
        </Text>
      )}
      <form className={classes.form}>
        <div className={classes.inputWrapper}>
          <label htmlFor={AMOUNT.NAME} className={labelClasses}>
            {AMOUNT.LABEL}
          </label>

          <input
            type={'number'}
            className={inputClasses}
            id={AMOUNT.NAME}
            {...register(AMOUNT.NAME, AMOUNT.VALIDATIONS)}
          />
        </div>
        {!isNilOrEmpty(dotPath('message', errors[AMOUNT.NAME])) && (
          <Text>{dotPath('message', errors[AMOUNT.NAME])}</Text>
        )}

        <button
          onClick={handleSubmit(handleSubmitClick)}
          className={submitClasses}
        >
          {!loading ? (
            <Text>{donateButtonText}</Text>
          ) : (
            <div className={classes.spinner} />
          )}
        </button>
      </form>
      {!isNilOrEmpty(legalNonProfitText) && (
        <Text size="subhead3" className={classes.legal}>
          {legalNonProfitText}
        </Text>
      )}
    </div>
  )
}
