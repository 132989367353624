import { createUseStyles } from 'react-jss'

import { COLOR, MQ, FONTS } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    background: COLOR.WHITE,
    display: 'flex',
    flexDirection: 'column',
    padding: [65, 0],
    [MQ.TABLET]: {
      padding: [100, 0],
    },
    [MQ.DESKTOP]: {
      padding: [190, 0],
    },
  },
  title: {
    width: '65%',
    textAlign: 'center',
    color: COLOR.BLACK,
    margin: 'auto',
    padding: [0, 0, 40],
    [MQ.TABLET]: {
      padding: [0, 0, 45],
    },
    [MQ.DESKTOP]: {
      padding: [0, 0, 90],
    },
  },
  container: {
    margin: 'auto',
    width: '83%',
    display: 'flex',
    flexDirection: 'column',
    [MQ.TABLET]: {
      justifyContent: 'space-between',
    },
    [MQ.DESKTOP]: {
      width: '65%',
      maxWidth: 1014,
    },
  },
  row: {
    [MQ.TABLET]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  column: {
    [MQ.TABLET]: {
      width: '48%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  inputWrapper: {
    borderRadius: 5,
    fontFamily: FONTS.PROXIMA_NOVA,
    margin: [7, 0, 20],
  },
  label: {
    fontFamily: FONTS.PROXIMA_NOVA,
    left: 20,
    lineHeight: '58px',
    color: COLOR.GREY_2,
    zIndex: 30,
    fontWeight: 400,
  },
  input: {
    fontFamily: FONTS.PROXIMA_NOVA,
    padding: [16, 18],
    background: COLOR.GREY_1,
    fontWeight: 400,
    borderRadius: 5,
  },
  topLabel: {
    margin: 0,
    color: COLOR.NAVY,
  },
  button: {
    backgroundColor: COLOR.BLACK,
    marginTop: 35,
    alignSelf: 'flex-start',
    [MQ.TABLET]: {
      marginTop: 50,
    },
    [MQ.DESKTOP]: {
      marginTop: 80,
    },
  },
  buttonError: {
    backgroundColor: COLOR.GREY_2,
  },
  inputError: {
    color: COLOR.RED,
  },

  thankYou: {
    textAlign: 'center',
    color: COLOR.BLACK,
  },
})
