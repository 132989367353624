import * as React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { dotPath, isNilOrEmpty } from 'ramdasauce'

import { useStyles } from './styles'
import { Text } from '@atoms'

export const HeroImage = ({ data }) => {
  const classes = useStyles()

  const centerImage = dotPath('centerImage', data)

  const isTextPresent =
    !isNilOrEmpty(data.titleText) || !isNilOrEmpty(centerImage)

  const centerImageSvg = isNilOrEmpty(dotPath('gatsbyImageData', centerImage))

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.imageOverlay} />

        {!isNilOrEmpty(data.backgroundImage) && (
          <GatsbyImage
            image={data.backgroundImage.gatsbyImageData}
            alt={data.backgroundImage.title}
            className={classes.image}
            placeholder="none"
          />
        )}
        {isTextPresent && (
          <div className={classes.textBlock}>
            <Text size="header1" tag="h1" className={classes.title}>
              {data.titleText}
            </Text>
            {centerImageSvg ? (
              <img
                src={`https:${dotPath('file.url', centerImage)}`}
                className={classes.centerImage}
                alt={dotPath('title', centerImage)}
              />
            ) : (
              <GatsbyImage
                image={getImage(centerImage)}
                alt={dotPath('title', centerImage)}
                className={classes.centerImage}
                loading="eager"
                placeholder="none"
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}

HeroImage.propTypes = {
  data: PropTypes.object,
}

HeroImage.defaultProps = {
  data: {},
}
