import { createUseStyles } from 'react-jss'

import { COLOR } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    width: '100%',
    color: COLOR.BLACK,
    textTransform: 'uppercase',
    '& a': {
      color: COLOR.BLACK,
    },
  },
  agency: {
    display: 'block',
    padding: [0, 0, 25],
  },
  date: {
    padding: [25, 0, 14],
  },
  title: {
    padding: [0, 0, 40],
  },
  ctaLink: {},
  image: {
    filter: 'grayscale(1)',
  },
})
