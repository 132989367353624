import React from 'react'

export const email = () => {
  return (
    <svg
      width="28"
      height="25"
      viewBox="0 0 28 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.87097 2.59178C3.32362 2.59178 2.67241 3.12797 2.67241 4.03027V20.3662C2.67241 21.2685 3.32362 21.8047 3.87097 21.8047H23.7328C24.2802 21.8047 24.9314 21.2685 24.9314 20.3662V4.03027C24.9314 3.12797 24.2802 2.59178 23.7328 2.59178H3.87097ZM0.104065 4.03027C0.104065 1.93764 1.68731 0.0234375 3.87097 0.0234375H23.7328C25.9165 0.0234375 27.4997 1.93764 27.4997 4.03027V20.3662C27.4997 22.4589 25.9165 24.3731 23.7328 24.3731H3.87097C1.68731 24.3731 0.104065 22.4589 0.104065 20.3662V4.03027Z"
        fill="#E9A73E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.369595 3.24874C0.801458 2.68616 1.60762 2.58019 2.1702 3.01205L13.8019 11.9411L25.4336 3.01205C25.9962 2.58019 26.8023 2.68616 27.2342 3.24874C27.6661 3.81132 27.5601 4.61748 26.9975 5.04934L14.5839 14.5786C14.1227 14.9326 13.4811 14.9326 13.0199 14.5786L0.606283 5.04934C0.0437003 4.61748 -0.0622686 3.81132 0.369595 3.24874Z"
        fill="#E9A73E"
      />
    </svg>
  )
}
