import { createUseStyles } from 'react-jss'

import { COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  button: {
    borderRadius: 118,
    backgroundColor: 'transparent',
    border: `2px solid ${COLOR.BLACK}`,
  },
  buttonText: {
    padding: [15, 24, 12],
    textDecoration: 'none',
    display: 'inline-block',
    color: COLOR.BLACK,
    [MQ.DESKTOP]: {
      padding: [18, 45, 16],
    },
  },
  black: {
    background: COLOR.BLACK,
    '& a': {
      '& span': {
        color: COLOR.WHITE,
      },
    },
  },
  blue: {
    backgroundColor: COLOR.BLUE,
    color: COLOR.WHITE,
    border: `5px solid ${COLOR.BLUE}`,

    '&:active': {
      transition: '0.1s',
      transform: 'scale(1.1)',
      '& a': {
        '& span': {
          color: COLOR.WHITE,
        },
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      '& a': {
        '& span': {
          color: COLOR.BLUE,
        },
      },
      '&:active': {
        backgroundColor: COLOR.BLUE,
        transition: '0.1s',
      },
    },
  },
  navy: {
    backgroundColor: COLOR.NAVY,
    color: COLOR.WHITE,
    border: `5px solid ${COLOR.NAVY}`,

    '&:active': {
      transition: '0.1s',
      transform: 'scale(1.1)',
      '& a': {
        '& span': {
          color: COLOR.WHITE,
        },
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      '& a': {
        '& span': {
          color: COLOR.NAVY,
        },
      },
      '&:active': {
        backgroundColor: COLOR.NAVY,
        transition: '0.1s',
      },
    },
  },

  white: {
    backgroundColor: COLOR.WHITE,
    border: `5px solid ${COLOR.WHITE}`,
    '&:active': {
      transition: '0.1s',
      transform: 'scale(1.1)',
      '& a': {
        '& span': {
          color: COLOR.NAVY,
        },
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      '& a': {
        '& span': {
          color: COLOR.WHITE,
        },
      },
      '&:active': {
        backgroundColor: COLOR.WHITE,
        transition: '0.1s',
      },
    },
    '& a': {
      '& span': {
        color: COLOR.NAVY,
      },
    },
  },
})
