import * as React from 'react'
import { Link } from 'gatsby'

import { useStyles } from './styles'
import { Layout } from '@organisms'
import { Text } from '@atoms'
import { ROUTES } from '@constants'

export const NotFoundView = () => {
  const classes = useStyles()

  return (
    <Layout>
      <div className={classes.wrapper}>
        <div>
          <Text className={classes.title} size="header2" tag="p">
            Page not found...
          </Text>
          <Text tag="p">
            Sorry, the page you were looking for doesn't exist.
          </Text>
          <Link className={classes.backButton} to={ROUTES.HOME}>
            <Text size="button">Go back home</Text>
          </Link>
        </div>
      </div>
    </Layout>
  )
}
