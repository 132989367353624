import * as React from 'react'
import { isNilOrEmpty, dotPath } from 'ramdasauce'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import { useStyles } from './styles'

export const LogoItem = ({ image, link }) => {
  const classes = useStyles()

  const isImage = !isNilOrEmpty(image)
  const isPng = isImage && !isNilOrEmpty(image.gatsbyImageData)

  const LinkWrapper = ({ children }) => {
    const linkType = !isNilOrEmpty(link) && link.__typename

    switch (linkType) {
      case 'ContentfulInternalLink': {
        const urlSlug = link.urlSlug
        // handles the homepage links since the url slug for that page is null by default
        const url = isNilOrEmpty(urlSlug) ? '' : urlSlug
        return <Link to={`/${url}`}>{children}</Link>
      }
      case 'ContentfulExternalLink': {
        return (
          <a
            href={dotPath('externalLinkUrl.externalLinkUrl', link)}
            target="_blank"
            rel="noreferrer"
          >
            {children}
          </a>
        )
      }
      default:
        return children
    }
  }

  return (
    <div>
      <LinkWrapper>
        {isImage &&
          (isPng ? (
            <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
          ) : (
            <img
              src={image.file.url}
              alt={image.title}
              className={classes.image}
            />
          ))}
      </LinkWrapper>
    </div>
  )
}
