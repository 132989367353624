import { COLOR, MQ } from '@constants'

export const styles = {
  '@global': {
    '.flickity-slider': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
    '.flickity-viewport': {
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
    },
    '.flickity-enabled': {
      outline: 'none',
    },
    '.carousel': {
      background: COLOR.WHITE,
      position: 'relative',
      width: '100%',
      height: '100%',
      padding: [50, 0],
      [MQ.TABLET]: {
        padding: [70, 0],
      },
      [MQ.DESKTOP]: {
        padding: [70, 0, 135],
      },
    },
  },
  title: {
    textAlign: 'center',
    padding: [0, 35, 40],
    [MQ.DESKTOP]: {
      paddingBottom: 90,
    },
  },
}
