import React, { useState } from 'react'
import { FORM, FIELDS } from './form'
import { isNilOrEmpty, dotPath } from 'ramdasauce'
import { useForm } from 'react-hook-form'
import cn from 'classnames'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { useStyles } from './styles'
import {
  InputText,
  InputTextarea,
  InputSelect,
  Text,
  Animated,
  FormButton,
} from '@atoms'

export const ContactForm = ({ title }) => {
  const classes = useStyles()

  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'onTouched',
  })

  const handleSuccess = () => {
    reset()
    setSubmitted(true)
    setSubmitting(false)
    ScrollTrigger.refresh()
  }

  const onSubmit = (data) => {
    setSubmitting(true)
    const xhr = new XMLHttpRequest()
    xhr.open(FORM.METHOD, FORM.ACTION)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        handleSuccess()
      }
    }
    xhr.send(JSON.stringify(data))
  }

  const formSubmittable = () => {
    return (
      isNilOrEmpty(errors) &&
      !isNilOrEmpty(watch(FIELDS.FULL_NAME.NAME)) &&
      !isNilOrEmpty(watch(FIELDS.PHONE.NAME)) &&
      !isNilOrEmpty(watch(FIELDS.EMAIL.NAME)) &&
      !isNilOrEmpty(watch(FIELDS.MESSAGE.NAME))
    )
  }

  const submitClasses = cn({
    [classes.button]: true,
    [classes.submittable]: formSubmittable(),
    [classes.buttonError]: !isNilOrEmpty(errors),
  })

  return (
    <div className={classes.wrapper}>
      <Animated type="fade-in">
        <div className={classes.title}>
          <Text size="header2">{title}</Text>
        </div>
        <div className={classes.container}>
          {!submitted && (
            <>
              <div className={classes.row}>
                <div className={classes.column}>
                  <Text tag="p" size="label" className={classes.topLabel}>
                    Full Name*
                  </Text>
                  <InputText
                    register={register}
                    name={FIELDS.FULL_NAME.NAME}
                    validations={FIELDS.FULL_NAME.VALIDATIONS}
                    label={FIELDS.FULL_NAME.LABEL}
                    currentValue={watch(FIELDS.FULL_NAME.NAME)}
                    error={dotPath(FIELDS.FULL_NAME.NAME, errors)}
                    className={classes.inputWrapper}
                    labelClassName={classes.label}
                    inputClassName={classes.input}
                    errorClass={classes.inputError}
                  />
                  <Text tag="p" size="label" className={classes.topLabel}>
                    Email*
                  </Text>
                  <InputText
                    register={register}
                    validations={FIELDS.EMAIL.VALIDATIONS}
                    name={FIELDS.EMAIL.NAME}
                    label={FIELDS.EMAIL.LABEL}
                    currentValue={watch(FIELDS.EMAIL.NAME)}
                    error={dotPath(FIELDS.EMAIL.NAME, errors)}
                    className={classes.inputWrapper}
                    labelClassName={classes.label}
                    inputClassName={classes.input}
                    errorClass={classes.inputError}
                  />
                  <Text tag="p" size="label" className={classes.topLabel}>
                    Phone
                  </Text>
                  <InputText
                    register={register}
                    validations={FIELDS.PHONE.VALIDATIONS}
                    name={FIELDS.PHONE.NAME}
                    label={FIELDS.PHONE.LABEL}
                    currentValue={watch(FIELDS.PHONE.NAME)}
                    error={dotPath(FIELDS.PHONE.NAME, errors)}
                    className={classes.inputWrapper}
                    labelClassName={classes.label}
                    inputClassName={classes.input}
                    errorClass={classes.inputError}
                  />
                </div>
                <div className={classes.column}>
                  <Text tag="p" size="label" className={classes.topLabel}>
                    Company
                  </Text>
                  <InputText
                    register={register}
                    validations={FIELDS.COMPANY.VALIDATIONS}
                    name={FIELDS.COMPANY.NAME}
                    label={FIELDS.COMPANY.LABEL}
                    currentValue={watch(FIELDS.COMPANY.NAME)}
                    error={dotPath(FIELDS.COMPANY.NAME, errors)}
                    className={classes.inputWrapper}
                    labelClassName={classes.label}
                    inputClassName={classes.input}
                  />
                  <Text tag="p" size="label" className={classes.topLabel}>
                    Interest
                  </Text>
                  <InputSelect
                    register={register}
                    validations={FIELDS.INTEREST.VALIDATIONS}
                    name={FIELDS.INTEREST.NAME}
                    label={FIELDS.INTEREST.LABEL}
                    currentValue={watch(FIELDS.INTEREST.NAME)}
                    error={dotPath(FIELDS.INTEREST.NAME, errors)}
                    options={FIELDS.INTEREST.OPTIONS}
                  />
                  <Text tag="p" size="label" className={classes.topLabel}>
                    How'd you hear about us?
                  </Text>
                  <InputSelect
                    register={register}
                    validations={FIELDS.REFERENCE.VALIDATIONS}
                    name={FIELDS.REFERENCE.NAME}
                    label={FIELDS.REFERENCE.LABEL}
                    currentValue={watch(FIELDS.REFERENCE.NAME)}
                    error={dotPath(FIELDS.REFERENCE.NAME, errors)}
                    options={FIELDS.REFERENCE.OPTIONS}
                  />
                </div>
              </div>
              <Text tag="p" size="label" className={classes.topLabel}>
                Message*
              </Text>
              <InputTextarea
                register={register}
                validations={FIELDS.MESSAGE.VALIDATIONS}
                name={FIELDS.MESSAGE.NAME}
                label={FIELDS.MESSAGE.LABEL}
                currentValue={watch(FIELDS.MESSAGE.NAME)}
                error={dotPath(FIELDS.MESSAGE.NAME, errors)}
                options={FIELDS.MESSAGE.OPTIONS}
                errorClass={classes.inputError}
              />
              <FormButton
                submitting={submitting}
                onSubmit={handleSubmit(onSubmit)}
                submitClasses={submitClasses}
                contact
              />
            </>
          )}
          {submitted && (
            <Text size="button" tag="p" className={classes.thankYou}>
              Thank you for reaching out!
            </Text>
          )}
        </div>
      </Animated>
    </div>
  )
}
