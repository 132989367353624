import * as React from 'react'
import { isNilOrEmpty, dotPath } from 'ramdasauce'

import { useStyles } from './styles'
import { Text, Animated } from '@atoms'
import { NewsTile } from '@molecules'

export const NewsGrid = ({ data }) => {
  const classes = useStyles()

  const title = dotPath('title', data)
  const layout = dotPath('gridLayout', data)

  const renderGrid = () => {
    return layout.map((item) => {
      return (
        <div className={classes.newsTileWrapper} key={item.id}>
          <NewsTile data={item} />
        </div>
      )
    })
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        {!isNilOrEmpty(title) && (
          <Text size="header2" className={classes.title}>
            {title}
          </Text>
        )}
        {!isNilOrEmpty(layout) && (
          <div className={classes.gridWrapper}>
            <Animated type="fade-in">{renderGrid()}</Animated>
          </div>
        )}
      </div>
    </div>
  )
}
