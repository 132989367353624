import { createUseStyles } from 'react-jss'

import { MQ } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    [MQ.TABLET_LARGE]: {
      width: '60%',
      margin: 'auto',
      transform: 'translateY(-100px)',
    },
    [MQ.DESKTOP]: {
      transform: 'translateY(-125px)',
    },
  },
  bodyWrapper: {
    width: '90%',
    margin: [50, 'auto'],
    maxWidth: 550,
    [MQ.TABLET_LARGE]: {
      margin: [75, 'auto'],
      maxWidth: 'unset',
    },
  },
})
