import React from 'react'

export const arrow = () => {
  return (
    <svg
      width="55"
      height="42"
      viewBox="0 0 55 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.626 2.65479V25.2071L38.7631 14.0658C37.7822 11.5048 36.2883 9.17153 34.3731 7.20922C32.4579 5.2469 30.1618 3.69702 27.626 2.65479Z"
        fill="black"
      />
      <path
        d="M25.8673 29.4524V2.06826C21.6132 0.783449 17.0529 0.949906 12.9037 2.54145C8.75452 4.13299 5.252 7.05928 2.94717 10.86C0.642327 14.6607 -0.334027 19.12 0.171705 23.5365C0.677438 27.953 2.63655 32.076 5.74084 35.2568C8.84513 38.4377 12.9184 40.4958 17.3199 41.1075C21.7213 41.7192 26.2012 40.8497 30.0546 38.6358C33.9081 36.4219 36.9164 32.9892 38.6064 28.8779C40.2964 24.7665 40.5721 20.2098 39.3902 15.9245L25.8673 29.4524Z"
        fill="black"
      />
      <path
        d="M27.6257 2.06824H50.75L38.7746 14.0539C39.009 14.6403 39.2142 15.2853 39.3607 15.9245L54.9997 0.309082H25.8672V2.06824C26.4534 2.25001 27.0395 2.46111 27.6257 2.69567V2.06824Z"
        fill="black"
      />
      <path
        d="M39.39 15.9247C39.2142 15.2855 39.009 14.6639 38.8039 14.0541L27.6667 25.1954V2.65478C27.0805 2.42022 26.4944 2.20912 25.9082 2.02734V29.4525L39.39 15.9247Z"
        fill="white"
      />
    </svg>
  )
}
