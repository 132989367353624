import * as React from 'react'
import { isNilOrEmpty, dotPath } from 'ramdasauce'
import cn from 'classnames'

import { useStyles } from './styles'
import { CirclePhotoItem } from '@molecules'
import { Text, Animated } from '@atoms'

export const CirclePhotoGrid = ({ data }) => {
  const classes = useStyles()
  const gridData = !isNilOrEmpty(data) && data.gridLayout
  const titleColor = dotPath('itemTitleColor', data)
  const titlePosition = dotPath('positionOfItemTitle', data)
  const itemsPerRow = dotPath('itemsPerRow', data)

  const titlePositionFallback = !isNilOrEmpty(titlePosition)
    ? titlePosition
    : false

  const containerClasses = cn({
    [classes.container]: true,
    [classes.fourItems]: itemsPerRow,
  })

  const renderGrid =
    gridData &&
    gridData.map((item) => {
      const itemProps = {
        ...item,
        color: titleColor,
        titleFirst: titlePositionFallback,
      }
      return (
        <div className={classes.itemWrapper} key={item.id}>
          <CirclePhotoItem {...itemProps} />
        </div>
      )
    })

  return (
    <div className={classes.wrapper}>
      <Animated type="fade-in">
        <div>
          <Text tag="h3" size="header2" className={classes.title}>
            {data.title}
          </Text>
        </div>
      </Animated>
      <div className={containerClasses}>
        <Animated>{renderGrid}</Animated>
      </div>
    </div>
  )
}
