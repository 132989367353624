import { createUseStyles } from 'react-jss'

import { COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    marginBottom: 70,
    background: COLOR.WHITE,
    [MQ.TABLET]: {
      marginBottom: 195,
    },
    [MQ.DESKTOP]: {
      marginBottom: 240,
    },
  },
  title: {
    padding: [0, 30],
    textAlign: 'center',
    margin: 'auto',
    [MQ.TABLET]: {
      width: '50%',
    },
    [MQ.DESKTOP]: {
      width: '40%',
    },
  },
  blogWrapper: {
    marginBottom: 100,
    background: COLOR.WHITE,
    [MQ.TABLET]: {
      marginBottom: 150,
    },
    [MQ.DESKTOP]: {
      marginBottom: 100,
      transform: 'translateY(-75px)',
    },
  },
  blog: {
    '& div:first-child > div > div': {
      [MQ.DESKTOP]: {
        padding: [50, 0, 0],
      },
    },
  },
})
