import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { isNilOrEmpty } from 'ramdasauce'

import { useStyles } from './styles'

const TAG = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  p: 'p',
  span: 'span',
}

export const Text = ({ children, tag, size, className }) => {
  const classes = useStyles()

  const getSize = classes[size.toLowerCase()]
  const getTag = TAG[tag.toLowerCase()]
  const DynamicElement = getTag
  const useClassName = !isNilOrEmpty(className) && className
  return (
    <DynamicElement className={cn(getSize, useClassName)}>
      {children}
    </DynamicElement>
  )
}

Text.propTypes = {
  tag: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.any,
}

Text.defaultProps = {
  tag: 'span',
  size: 'body1',
  className: null,
}
