import * as React from 'react'
import { isNilOrEmpty, dotPath } from 'ramdasauce'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import { useStyles } from './styles'
import { Text, ContentfulButton, Animated } from '@atoms'

export const TextSection = ({ data }) => {
  const classes = useStyles()

  const image = dotPath('image', data)
  const backgroundColor = dotPath('backgroundColor', data)
  const backgroundColorFallback = !isNilOrEmpty(backgroundColor)
    ? backgroundColor
    : false

  const wrapperClasses = cn({
    [classes.wrapper]: !backgroundColorFallback,
    [classes.grey]: backgroundColorFallback,
  })

  const textBlockClasses = cn({
    [classes.textBlock]: true,
    [classes.imagePresent]: !isNilOrEmpty(image),
  })

  return (
    <div className={wrapperClasses}>
      <div className={classes.container}>
        {!isNilOrEmpty(image) && (
          <Animated type="fade-in">
            <div className={classes.image}>
              <GatsbyImage
                image={getImage(image)}
                alt={dotPath('title', image)}
              />
            </div>
          </Animated>
        )}
        <div className={textBlockClasses}>
          <Animated type="fade-in">
            {!isNilOrEmpty(dotPath('title', data)) && (
              <div className={classes.title}>
                <Text tag="h3" size="header2">
                  {data.title}
                </Text>
              </div>
            )}
            {!isNilOrEmpty(data.paragraph) && (
              <div className={classes.body}>
                <Text tag="p" size="body1">
                  {data.paragraph.paragraph}
                </Text>
              </div>
            )}
            {!isNilOrEmpty(data.ctaButton) && (
              <div>
                <ContentfulButton data={data.ctaButton} />
              </div>
            )}
          </Animated>
        </div>
      </div>
    </div>
  )
}
