import React from 'react'
import { dotPath, isNilOrEmpty, isUndefined } from 'ramdasauce'
import { getSrc } from 'gatsby-plugin-image'

import { Seo } from '@atoms'
import { HeroImage, HeroTextImage, TextSection } from '@molecules'
import {
  Carousel,
  Layout,
  PhotoTextGrid,
  CirclePhotoGrid,
  LogoGrid,
  NewsletterForm,
  ContactForm,
  Video,
  PhotoVideoGrid,
  TileGrid,
  NewsGrid,
} from '@organisms'

export const PageView = (props) => {
  const data = dotPath('props.data.contentfulPage', props)
  const navName = dotPath('title', data)
  const seoDesc = dotPath('seoMetaDescription', data)
  const seoImage = dotPath('seoImage', data)
  const seoImageSrc = getSrc(seoImage)
  const formattedSeoImage = !isUndefined(seoImageSrc) && 'https:' + seoImageSrc
  const layout = data.pageLayout
  const color = dotPath('color', data)

  const flashData = dotPath('props.data.contentfulFlashMessage', props)

  const layoutSwitch =
    !isNilOrEmpty(layout) &&
    layout.map((item) => {
      const typename = item.__typename
      switch (typename) {
        case 'ContentfulBackgroundImageHero': {
          return <HeroImage data={item} key={item.id} />
        }
        case 'ContentfulPhotoTextGrid': {
          return <PhotoTextGrid data={item} key={item.id} />
        }
        case 'ContentfulTextSection': {
          return <TextSection data={item} key={item.id} />
        }
        case 'ContentfulCircleImageTextGrid': {
          return <CirclePhotoGrid data={item} key={item.id} />
        }
        case 'ContentfulLogoGrid': {
          return <LogoGrid data={item} key={item.id} />
        }
        case 'ContentfulNewsletterForm': {
          return <NewsletterForm data={item} key={item.id} />
        }
        case 'ContentfulContactForm': {
          return <ContactForm {...item} key={item.id} />
        }
        case 'ContentfulVideo': {
          return <Video {...item} key={item.id} />
        }
        case 'ContentfulPhotoTextTileGrid': {
          return <PhotoVideoGrid data={item} key={item.id} />
        }
        case 'ContentfulBackgroundImageWTextHero': {
          return (
            <HeroTextImage data={{ ...item, color: color }} key={item.id} />
          )
        }
        case 'ContentfulBlogPostGrid': {
          return <TileGrid data={item} key={item.id} />
        }
        case 'ContentfulImageCarousel': {
          return <Carousel data={item} key={item.id} />
        }
        case 'ContentfulNewsGrid': {
          return <NewsGrid data={item} key={item.id} />
        }
        case 'ContentfulDonateFormHero': {
          return (
            <HeroTextImage data={{ ...item, color: color }} key={item.id} />
          )
        }

        default:
          return ''
      }
    })

  return (
    <Layout flashData={flashData}>
      <Seo title={navName} pageDesc={seoDesc} pageImage={formattedSeoImage} />
      {layoutSwitch}
    </Layout>
  )
}
