import * as React from 'react'
import { isNilOrEmpty } from 'ramdasauce'

import { useStyles } from './styles'
import { LogoItem } from '@molecules'
import { Text, Animated, ContentfulButton } from '@atoms'

export const LogoGrid = ({ data }) => {
  const classes = useStyles()

  const gridData = data.gridLayout
  const renderGrid =
    !isNilOrEmpty(gridData) &&
    gridData.map((item) => {
      return (
        <div className={classes.svgWrapper} key={item.id}>
          <LogoItem {...item} />
        </div>
      )
    })

  return (
    <div className={classes.wrapper}>
      <Animated type="fade-in">
        <div className={classes.title}>
          <Text tag="h3" size="header7">
            {data.title}
          </Text>
        </div>
      </Animated>
      <div className={classes.container}>
        <Animated>{renderGrid}</Animated>
      </div>
      {!isNilOrEmpty(data.ctaButton) && (
        <div className={classes.buttonWrapper}>
          <ContentfulButton data={data.ctaButton} />
        </div>
      )}
    </div>
  )
}
