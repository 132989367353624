import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { dotPath, isNilOrEmpty } from 'ramdasauce'
import cn from 'classnames'

import { useStyles } from './styles'
import { Animated, Text } from '@atoms'

export const Video = ({ video, title, backgroundColor }) => {
  const classes = useStyles()

  const [play, setPlay] = useState(false)
  const videoRef = useRef()

  const videoSrc = dotPath('file.url', video)

  useEffect(() => {
    if (play) {
      videoRef.current.play()
    }
  }, [play])

  const wrapperClasses = cn({
    [classes.wrapper]: true,
    [classes.lightBlue]: backgroundColor,
  })
  const titleClasses = cn({
    [classes.title]: true,
    [classes.white]: backgroundColor,
  })

  return (
    <div className={wrapperClasses}>
      <div className={classes.container}>
        {!isNilOrEmpty(title) && (
          <Animated type="fade-in">
            <div className={titleClasses}>
              <Text size="header1" tag="h3">
                {title}
              </Text>
            </div>
          </Animated>
        )}
        <Animated>
          <div>
            <video
              src={videoSrc}
              controls={play}
              className={classes.video}
              preload="metadata"
              ref={videoRef}
              controlsList="nodownload"
            />
            {!play && (
              <button
                className={classes.playButton}
                onClick={() => setPlay(true)}
              >
                <span className={classes.hidden}>Play</span>
              </button>
            )}
          </div>
        </Animated>
      </div>
    </div>
  )
}

Video.propTypes = {
  text: PropTypes.string,
}

Video.defaultProps = {
  text: 'Video',
}
