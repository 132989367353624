import React from 'react'

export const visionHover = () => {
  return (
    <svg
      width="61"
      height="53"
      viewBox="0 0 61 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4822 18.3291L30.2299 28.0512L39.9328 18.3291C37.0112 16.5209 33.6434 15.563 30.2075 15.563C26.7716 15.563 23.4038 16.5209 20.4822 18.3291Z"
        fill="white"
      />
      <path
        d="M41.5298 19.4404L30.2299 30.785L18.8853 19.4404C15.8358 21.8019 13.601 25.0577 12.4936 28.7522C11.3862 32.4467 11.4618 36.395 12.7096 40.0445C13.9574 43.694 16.3151 46.862 19.4527 49.1051C22.5902 51.3482 26.3506 52.5541 30.2075 52.5541C34.0644 52.5541 37.8248 51.3482 40.9624 49.1051C44.0999 46.862 46.4576 43.694 47.7054 40.0445C48.9533 36.395 49.0288 32.4467 47.9214 28.7522C46.8141 25.0577 44.5793 21.8019 41.5298 19.4404Z"
        fill="white"
      />
      <path
        d="M4.83224 2.67902H55.5828L39.9328 18.329C40.4822 18.6739 41.0188 19.0444 41.5298 19.4404L60.2075 0.762695H0.20752L18.8853 19.4404C19.3963 19.0444 19.9328 18.6739 20.4822 18.329L4.83224 2.67902Z"
        fill="white"
      />
    </svg>
  )
}
