import * as React from 'react'
import PropTypes from 'prop-types'
import { dotPath, isNilOrEmpty } from 'ramdasauce'
import cn from 'classnames'

import { useStyles } from './styles'
import { PhotoTextItem } from '@molecules'
import { Animated, Text } from '@atoms'

export const PhotoTextGrid = ({ data, blogPost }) => {
  const classes = useStyles()

  const gridData = data.gridLayout
  const title = dotPath('title', data)

  const renderGrid =
    !isNilOrEmpty(gridData) &&
    gridData.map((item) => {
      const animationDirection = item.photoSide ? 'fade-left' : 'fade-right'
      return (
        <Animated type={animationDirection} key={item.id}>
          <div>
            <PhotoTextItem {...item} blogPost={blogPost} />
          </div>
        </Animated>
      )
    })

  const wrapperClasses = cn({
    [classes.wrapper]: true,
    [classes.blogWrapper]: blogPost,
  })

  return (
    <div className={wrapperClasses}>
      {!isNilOrEmpty(title) && (
        <Text size="header2" tag="p" className={classes.title}>
          {title}
        </Text>
      )}
      <div className={cn({ [classes.blog]: blogPost })}>{renderGrid}</div>
    </div>
  )
}

PhotoTextGrid.propTypes = {
  data: PropTypes.object,
  blogPost: PropTypes.bool,
}

PhotoTextGrid.defaultProps = {
  data: null,
  blogPost: false,
}
