import { createUseStyles } from 'react-jss'

import { COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    background: COLOR.WHITE,
  },
  container: {
    display: 'grid',
    justifyItems: 'center',
    gridTemplateColumns: '90%',
    width: '86%',
    gridGap: '50px 7%',
    margin: [65, 'auto', 65],
    justifyContent: 'center',
    maxWidth: 1232,
    [MQ.TABLET]: {
      gridTemplateColumns: '46.5% 46.5%',
      width: '77%',
      gridGap: '75px 8.75%',
      margin: [85, 'auto'],
    },
    [MQ.TABLET_LARGE]: {
      gridTemplateColumns: '27.5% 27.5% 27.5%',
    },
    [MQ.DESKTOP]: {
      margin: [85, 'auto', 135],
      width: '79%',
      gridGap: '115px 8.75%',
    },
  },
})
