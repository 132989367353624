import React from 'react'

export const contactHover = () => {
  return (
    <svg
      width="61"
      height="58"
      viewBox="0 0 61 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.1541 39.68H18.4121L0.522461 0H17.2471L35.1541 39.68ZM19.2716 38.371H33.1252L16.4049 1.309H2.54704L19.2716 38.371Z"
        fill="white"
      />
      <path
        d="M60.5226 39.0253H45.2336L27.9199 0.654297H43.209L60.5226 39.0253Z"
        fill="white"
      />
      <path
        d="M13.3988 57.9402C17.6666 57.9402 21.1263 54.4805 21.1263 50.2128C21.1263 45.945 17.6666 42.4854 13.3988 42.4854C9.13108 42.4854 5.67139 45.945 5.67139 50.2128C5.67139 54.4805 9.13108 57.9402 13.3988 57.9402Z"
        fill="white"
      />
      <path
        d="M44.2211 57.9402C48.4888 57.9402 51.9485 54.4805 51.9485 50.2128C51.9485 45.945 48.4888 42.4854 44.2211 42.4854C39.9533 42.4854 36.4937 45.945 36.4937 50.2128C36.4937 54.4805 39.9533 57.9402 44.2211 57.9402Z"
        fill="white"
      />
    </svg>
  )
}
