import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { dotPath, isNilOrEmpty } from 'ramdasauce'
import cn from 'classnames'

import { useStyles } from './styles'
import { Text, Animated } from '@atoms'
import { TileItem } from '@molecules'

export const TileGrid = ({ data, altHeader }) => {
  const classes = useStyles()

  const [page, setPage] = useState(dotPath('paginateTiles', data) ? 4 : 10)

  const paginate = (data, size) => {
    return data.slice(0, size)
  }

  const gridData = dotPath('gridLayout', data)
  const paginatedData = paginate(gridData, page)

  const renderGrid = (gridItems) => {
    if (isNilOrEmpty(gridItems)) {
      return
    }
    return gridItems.map((tile) => {
      return (
        <Animated type="fade-in" key={tile.id}>
          <div className={classes.tileWrapper}>
            <TileItem data={tile} />
          </div>
        </Animated>
      )
    })
  }

  const handleClick = () => {
    setPage(page + 4)
  }

  const wrapperClasses = cn({
    [classes.wrapper]: true,
    [classes.altWrapper]: dotPath('backgroundColor', data),
  })

  return (
    <div className={wrapperClasses}>
      {!isNilOrEmpty(dotPath('title', data)) && (
        <Text tag="h3" size="header2" className={classes.title}>
          {data.title}
        </Text>
      )}
      {!isNilOrEmpty(dotPath('subtitle', data)) && (
        <Text tag="p" size="body17" className={classes.body}>
          {data.subtitle}
        </Text>
      )}
      <div className={classes.videoContainer}>{renderGrid(paginatedData)}</div>

      {page < gridData.length && (
        <button className={classes.viewMore} onClick={handleClick}>
          <Text>View More</Text>
        </button>
      )}
    </div>
  )
}

TileGrid.propTypes = {
  altHeader: PropTypes.bool,
}

TileGrid.defaultProps = {
  altHeader: false,
}
