import { createUseStyles } from 'react-jss'

import { COLOR, MQ, Z_INDEX } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    color: COLOR.WHITE,
    width: '100%',
  },
  imageWrapper: {
    position: 'relative',
    border: 'none',
    padding: 0,
    margin: 0,
    outline: 'none',
    cursor: 'pointer',
    backgroundColor: COLOR.GREY_1,
    width: '100%',
  },
  image: {
    width: '100%',
  },
  title: {
    margin: [30, 0, 5],
    [MQ.TABLET]: {
      margin: [30, 0, 10],
    },
    [MQ.DESKTOP]: {
      margin: [50, 0, 15],
    },
  },
  playButton: {
    backgroundColor: COLOR.GREEN,
    borderRadius: '50%',
    width: 75,
    height: 75,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate( -50%, -50%)',
    '&:active': {
      width: 82.5,
      height: 82.5,
      transition: '0.05s',
    },
    '&:hover': {
      backgroundColor: COLOR.GREEN_CLICKED,
    },
    '&::after': {
      position: 'absolute',
      left: '55%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      content: `""`,
      width: '0',
      height: '0',
      borderStyle: 'solid',
      borderWidth: '18px 0 18px 30px',
      borderColor: 'transparent transparent transparent #ffffff',
    },
  },
  hidden: {
    position: 'absolute',
    left: -1000,
  },

  modalWrapper: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgba( 0, 0, 0, 0.6)',
    position: 'fixed',
    zIndex: Z_INDEX.MODAL_BACKGROUND,
  },
  modalFlex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80%',
    [MQ.TABLET_LARGE]: {
      marginTop: '10vh',
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    '& iframe': {
      width: '100%',
      height: 'calc( 100vw * .5625 )',
      order: 5,
      [MQ.TABLET_LARGE]: {
        width: 'calc( 65vh * 1.777777777 )',
        height: '65vh',
        order: 1,
      },
    },
  },
  mobileFlip: {
    '& iframe': {
      height: '60vh',
    },
  },
  heightToggle: {
    '& iframe': {
      [MQ.TABLET_LARGE]: {
        width: '80%',
        height: 'calc( (100vw * .5625 ) * .8 )',
      },
    },
  },
  closeModal: {
    backgroundColor: 'rgba(0,0,0,0)',
    border: 'none',
    height: 50,
    width: 50,
    cursor: 'pointer',
    marginTop: 50,
    order: 1,
    outline: 'none',
    [MQ.TABLET_LARGE]: {
      marginTop: 0,
      marginLeft: 25,
    },
  },

  firstLine: {
    position: 'relative',
    height: 50,
    width: 50,
    '&:before': {
      position: 'absolute',
      content: '""',
      height: 35,
      width: 3,
      backgroundColor: COLOR.GREEN,
      transform: 'rotate(45deg)',
      [MQ.TABLET_LARGE]: {
        height: 75,
      },
    },
    '&:after': {
      position: 'absolute',
      content: '""',
      height: 35,
      width: 3,
      backgroundColor: COLOR.GREEN,
      transform: 'rotate(-45deg)',
      [MQ.TABLET_LARGE]: {
        height: 75,
      },
    },
  },
  button: {
    height: 100,
    width: 100,
  },
})
