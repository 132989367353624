import { createUseStyles } from 'react-jss'
import { Z_INDEX, TYPOGRAPHY, COLOR } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
    backgroundColor: COLOR.WHITE,
    WebkitBorderRadius: '50px',
  },
  input: {
    border: 'none',
    padding: '20px 26px 16px',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    outline: 'none',
    ...TYPOGRAPHY.PROXIMA_NOVA,
    position: 'relative',
    fontSize: 18,
    zIndex: Z_INDEX.CONTENT,
    textAlign: 'left',
    letterSpacing: '0.05em',
    backgroundColor: 'transparent',
    fontWeight: 400,
  },
  label: {
    position: 'absolute',
    letterSpacing: '0.05em',
    ...TYPOGRAPHY.PROXIMA_NOVA,
    fontSize: 18,
    left: 28,
    textAlign: 'left',
    lineHeight: '70px',
    zIndex: Z_INDEX.BACKGROUND,
    color: COLOR.GREY_2,
  },
  hidden: {
    display: 'none',
  },
})
