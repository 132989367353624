import * as React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { isNilOrEmpty } from 'ramdasauce'

import { useStyles } from './styles'
import { Text } from '@atoms'

export const CarouselItem = ({ image, imageCaption }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      {!isNilOrEmpty(image) && (
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.title}
          className={classes.image}
        />
      )}
      {!isNilOrEmpty(imageCaption) && (
        <div className={classes.captionWrapper}>
          <Text size="subhead2" tag="p" className={classes.caption}>
            {imageCaption}
          </Text>
        </div>
      )}
    </div>
  )
}
