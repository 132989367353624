import { createUseStyles } from 'react-jss'

import { COLOR, MQ } from '@constants'

export const useStyles = createUseStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: [50, 20],
    [MQ.TABLET]: {
      padding: [100, 20],
    },
    [MQ.DESKTOP]: {
      padding: [150, 20],
    },
  },

  backButton: {
    backgroundColor: COLOR.BLACK,
    color: COLOR.WHITE,
    padding: [15, 30],
    textDecoration: 'none',
    margin: [25, 0],
    display: 'inline-block',
    [MQ.DESKTOP]: {
      padding: [20, 40],
    },
  },
})
