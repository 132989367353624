import * as React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { isNilOrEmpty } from 'ramdasauce'

import { useStyles } from './styles'
import { Text } from '@atoms'

export const PhotoItem = ({ image, title, paragraph }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      {!isNilOrEmpty(image) && (
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.title}
          className={classes.image}
        />
      )}
      {!isNilOrEmpty(title) && (
        <Text tag="h3" size="body3" className={classes.title}>
          {title}
        </Text>
      )}
      {!isNilOrEmpty(paragraph) && (
        <Text tag="p" size="body1" className={classes.paragraph}>
          {paragraph.paragraph}
        </Text>
      )}
    </div>
  )
}

PhotoItem.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object,
  paragraph: PropTypes.object,
}

PhotoItem.defaultProps = {
  title: '',
  image: null,
  paragraph: null,
}
