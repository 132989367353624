import { createUseStyles } from 'react-jss'

import { COLOR, MQ, TYPOGRAPHY } from '@constants'

export const useStyles = createUseStyles({
  '@keyframes rotate': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(359deg)' },
  },
  wrapper: {
    maxWidth: 700,
  },

  button: {
    outline: 'none',
    border: 'none',
    padding: [10, 15],
    background: COLOR.WHITE,
    [MQ.TABLET]: {
      padding: [10, 25],
    },
    [MQ.TABLET_LARGE]: {
      padding: [10, 45],
    },
  },
  active: {
    background: COLOR.YELLOW,
  },
  oneTime: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  monthly: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  give: {
    textTransform: 'uppercase',
    margin: [30, 0],
  },
  submit: {
    alignSelf: 'flex-start',
    outline: 'none',
    border: 'none',
    background: COLOR.YELLOW,
    padding: [15, 24, 12],
    textDecoration: 'none',
    color: COLOR.BLACK,
    borderRadius: 118,
    marginTop: 30,
    [MQ.DESKTOP]: {
      padding: [18, 45, 16],
    },
  },
  legal: {
    textTransform: 'uppercase',
    fontWeight: 500,
    marginTop: 30,
    display: 'block',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputWrapper: {
    alignSelf: 'flex-start',
    position: 'relative',
    '&:before': {
      content: "'$'",
      position: 'absolute',
      color: COLOR.BLACK,
      left: 20,
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
      ...TYPOGRAPHY.PROXIMA_NOVA,
      fontSize: 18,
    },
    '&:after': {
      content: "'USD'",
      position: 'absolute',
      color: COLOR.BLACK,
      right: 20,
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
      ...TYPOGRAPHY.PROXIMA_NOVA,
      fontSize: 18,
    },
  },
  input: {
    border: 'none',
    outline: 'none',
    padding: [10, 70, 10, 40],
    maxWidth: 150,
    borderRadius: 5,
    position: 'relative',
    ...TYPOGRAPHY.PROXIMA_NOVA,
    fontSize: 18,
    [MQ.TABLET]: {
      maxWidth: 'unset',
      padding: [10, 70, 10, 40],
    },
  },
  label: {
    position: 'absolute',
    left: '-200vw',
  },
  error: {
    color: COLOR.RED,
  },
  buttonError: {
    backgroundColor: COLOR.GREY_2,
  },
  spinner: {
    height: 26,
    width: 26,
    borderRadius: 13,
    border: '3px solid black',
    borderBottom: `3px solid ${COLOR.YELLOW}`,
    borderLeft: `3px solid ${COLOR.YELLOW}`,
    backgroundColor: COLOR.YELLOW,
    animation: '$rotate 1s infinite linear',
    margin: [0.5, 18.5],
  },
})
