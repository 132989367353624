import React, { Component } from 'react'
import { CarouselItem } from '@molecules'
import Flickity from 'react-flickity-component'
import injectSheet from 'react-jss'
import { isNilOrEmpty, dotPath } from 'ramdasauce'

import { styles } from './styles'
import { Animated, Text } from '@atoms'

class Carousel extends Component {
  carouselContent = () => {
    const carouselData = this.props.data.carouselLayout
    // this is to ensure there is always enough content to fill the screen up to 4k
    // Flickity gets glitchy if there is not enough content
    const arrayTwice = carouselData.concat(carouselData)
    const arrayFour = arrayTwice.concat(arrayTwice)
    return arrayFour.map((item, index) => {
      return <CarouselItem key={index} {...item} />
    })
  }

  render() {
    const { options, classes } = this.props
    const carouselData = this.props.data.carouselLayout

    const dataLength =
      !isNilOrEmpty(carouselData) && this.props.data.carouselLayout.length
    const initialIndex = dataLength * 2

    const defaultOptions = {
      freeScroll: false,
      pageDots: false,
      prevNextButtons: false,
      wrapAround: true,
      initialIndex: initialIndex,
      contain: true,
      autoPlay: 2000,
    }

    const fullOptions = {
      ...defaultOptions,
      ...options,
    }

    const title = dotPath('data.title', this.props)

    return (
      <div className="carousel">
        <Animated type="fade-in">
          {!isNilOrEmpty(title) && (
            <div>
              <Text tag="h4" size="header2" className={classes.title}>
                {title}
              </Text>
            </div>
          )}
          <div>
            <Flickity
              flickityRef={(c) => (this.flkty = c)}
              options={fullOptions}
            >
              {!isNilOrEmpty(carouselData) && this.carouselContent()}
            </Flickity>
          </div>
        </Animated>
      </div>
    )
  }
}

export default injectSheet(styles)(Carousel)
