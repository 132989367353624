import React from 'react'

export const linkedIn = () => {
  return (
    <svg
      width="26"
      height="27"
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.4906 0.28125H2.50938C1.4 0.28125 0.5 1.21725 0.5 2.371V24.1915C0.5 25.3452 1.4 26.2812 2.50938 26.2812H23.4906C24.6 26.2812 25.5 25.3452 25.5 24.1915V2.371C25.5 1.21725 24.6 0.28125 23.4906 0.28125V0.28125ZM23.4906 24.2012C9.49687 24.198 2.5 24.1947 2.5 24.1915C2.50312 9.638 2.50625 2.36125 2.50938 2.36125C16.5031 2.3645 23.5 2.36775 23.5 2.371C23.4969 16.9245 23.4937 24.2012 23.4906 24.2012ZM4.20625 10.028H7.91562V22.4365H4.20625V10.028ZM6.0625 8.3315C7.24687 8.3315 8.2125 7.3305 8.2125 6.0955C8.2125 5.80186 8.15689 5.5111 8.04884 5.23982C7.94079 4.96854 7.78243 4.72204 7.58278 4.51441C7.38313 4.30678 7.14612 4.14207 6.88527 4.02971C6.62442 3.91734 6.34484 3.8595 6.0625 3.8595C5.78016 3.8595 5.50058 3.91734 5.23973 4.02971C4.97888 4.14207 4.74187 4.30678 4.54222 4.51441C4.34257 4.72204 4.18421 4.96854 4.07616 5.23982C3.96811 5.5111 3.9125 5.80186 3.9125 6.0955C3.90937 7.3305 4.87187 8.3315 6.0625 8.3315ZM13.9469 16.2972C13.9469 14.6787 14.2437 13.1122 16.1719 13.1122C18.0719 13.1122 18.1 14.9615 18.1 16.4012V22.4365H21.8062V15.631C21.8062 12.29 21.1125 9.71925 17.3594 9.71925C15.5562 9.71925 14.3469 10.7495 13.85 11.7245H13.8V10.028H10.2406V22.4365H13.9469V16.2972Z"
        fill="#E9A73E"
      />
    </svg>
  )
}
