import { createUseStyles } from 'react-jss'

import { COLOR, Z_INDEX, MQ } from '@constants'

export const useStyles = createUseStyles({
  headerWrapper: {
    backgroundColor: COLOR.WHITE,
  },
  headerContainer: {
    width: '100%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 100,
    [MQ.TABLET_LARGE]: {
      justifyContent: 'flex-start',
    },
  },
  headerLink: {
    color: COLOR.BLACK,
    textDecoration: 'none',
    lineHeight: '100%',
    marginLeft: 45,
    padding: [20, 0],
    [MQ.TABLET_LARGE]: {
      color: COLOR.BLUE,
    },
    '&:hover': {
      color: COLOR.DARKER_BLUE,
      '&:active': {
        color: COLOR.NAVY,
      },
    },
  },
  fresnelContainer: {
    width: '100%',
    height: 125,
    [MQ.TABLET_LARGE]: {
      height: 165,
    },
  },
  navContainer: {
    display: 'none',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
    [MQ.TABLET_LARGE]: {
      display: 'flex',
    },
  },
  logoContainer: {
    width: 125,
    height: 125,
    background: COLOR.BLACK,
    display: 'relative',
    [MQ.TABLET_LARGE]: {
      width: '11.4%',
      height: 165,
    },
  },
  home: {
    padding: [0, '20%'],
    display: 'inline-block',
    width: '60%',
    height: '100%',
  },

  link: {
    width: 'calc(100% - 10px)',
    height: 'calc(100% - 10px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: COLOR.BLACK,
    textDecoration: 'none',
    justifyContent: 'center',
    padding: [10, 0],
    [MQ.TABLET_LARGE]: {
      padding: 0,
      width: '16.6667%',
      height: '100%',
      '&:hover': {
        color: COLOR.WHITE,
      },
    },
    '& span': {
      marginTop: 10,
      display: 'inline-block',
      textTransform: 'uppercase',

      [MQ.DESKTOP]: {
        marginTop: 20,
      },
    },
  },
  svgWrapper: {
    width: 100,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    [MQ.TABLET_LARGE]: {
      width: 60,
      height: 60,
    },
  },
  sideNavLink: {
    color: COLOR.WHITE,
    border: 'none',
    padding: [10, 0],
    margin: 0,
    [MQ.TABLET_LARGE]: {
      padding: [15, 0],
    },
  },
  heightBroke: {
    display: 'none',
  },
  menuButton: {
    outline: 'none',
    background: 'rgba(0, 0, 0, 0)',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: 26,
    marginRight: 25,
    zIndex: Z_INDEX.MODAL,
    '&::before': {
      content: `''`,
      backgroundColor: COLOR.BLACK,
      width: 35,
      height: 5,
      transition: 'all 0.2s ease',
    },
  },
  lines: {
    width: 35,
    height: 26,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'absolute',
    '&::before': {
      content: `''`,
      backgroundColor: COLOR.BLACK,
      width: 35,
      height: 5,
      transition: 'all 0.2s ease',
    },
    '&::after': {
      content: `''`,
      backgroundColor: COLOR.BLACK,
      width: 35,
      height: 5,
      transition: 'all 0.2s ease',
    },
  },
  menuButtonOpen: {
    zIndex: Z_INDEX.MODAL,
    '&::before': {
      content: `''`,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      transition: 'all 0.2s ease',
    },
  },
  linesOpen: {
    '&::before': {
      transform: 'translateY(10px) rotate(-45deg)',
      backgroundColor: COLOR.BLACK,
    },
    '&::after': {
      transform: 'translateY(-11px) rotate(45deg)',
      backgroundColor: COLOR.BLACK,
    },
  },
  sideNav: {
    position: 'fixed',
    right: '-100vw',
    width: '100vw',
    height: '100vh',
    overflowY: 'scroll',
    backgroundColor: COLOR.WHITE,
    transition: 'all 0.3s ease',
    zIndex: Z_INDEX.NAV,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sideNavOpen: {
    right: 0,
    transition: 'all 0.3s ease',
  },
  mobileLinksWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '80%',
    padding: [165, 0, 100],
    maxWidth: 450,
  },
  headroom: {
    zIndex: Z_INDEX.NAV,
  },

  blue: {
    [MQ.TABLET_LARGE]: {
      '&:hover': {
        background: COLOR.BLUE,
      },
    },
  },
  red: {
    [MQ.TABLET_LARGE]: {
      '&:hover': {
        background: COLOR.RED,
      },
    },
  },
  yellow: {
    [MQ.TABLET_LARGE]: {
      '&:hover': {
        background: COLOR.YELLOW,
      },
    },
  },
  green: {
    [MQ.TABLET_LARGE]: {
      '&:hover': {
        background: COLOR.GREEN,
      },
    },
  },
  purple: {
    [MQ.TABLET_LARGE]: {
      '&:hover': {
        background: COLOR.PURPLE,
      },
    },
  },
  black: {
    [MQ.TABLET_LARGE]: {
      '&:hover': {
        background: COLOR.BLACK,
        color: COLOR.WHITE,
      },
    },
  },
  blueActive: {
    color: COLOR.BLUE,
    [MQ.TABLET_LARGE]: {
      color: COLOR.WHITE,
      background: COLOR.BLUE,
    },
  },
  redActive: {
    color: COLOR.RED,
    [MQ.TABLET_LARGE]: {
      color: COLOR.WHITE,
      background: COLOR.RED,
    },
  },
  yellowActive: {
    color: COLOR.YELLOW,
    [MQ.TABLET_LARGE]: {
      color: COLOR.WHITE,
      background: COLOR.YELLOW,
    },
  },
  greenActive: {
    color: COLOR.GREEN,
    [MQ.TABLET_LARGE]: {
      color: COLOR.WHITE,
      background: COLOR.GREEN,
    },
  },
  purpleActive: {
    color: COLOR.PURPLE,
    [MQ.TABLET_LARGE]: {
      color: COLOR.WHITE,
      background: COLOR.PURPLE,
    },
  },
  blackActive: {
    color: COLOR.BLACK,
    [MQ.TABLET_LARGE]: {
      color: COLOR.WHITE,
      background: COLOR.BLACK,
    },
  },
})
