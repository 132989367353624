import React, { useEffect, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useMeasure } from 'react-use'
import { dotPath, isNilOrEmpty } from 'ramdasauce'
import cn from 'classnames'

import { useStyles } from './styles'
import { Text, ContentfulButton } from '@atoms'

export const PhotoTextItem = ({
  titleText,
  paragraphText,
  photo,
  photoSide,
  ctaButton,
  blogPost,
}) => {
  const classes = useStyles()

  const [imageRef, imageSize] = useMeasure()
  const [textRef, textSize] = useMeasure()
  const [isTextTaller, setIsTextTaller] = useState(true)

  useEffect(() => {
    if (textSize.height > imageSize.height) {
      setIsTextTaller(true)
    } else {
      setIsTextTaller(false)
    }
  }, [imageSize, textSize])

  const containerClasses = cn({
    [classes.container]: true,
    [classes.flip]: !photoSide,
    [classes.tallText]: isTextTaller,
    [classes.blogContainer]: blogPost,
  })

  const titleClasses = cn({
    [classes.title]: true,
    [classes.blogTitle]: blogPost,
  })
  const imageClasses = cn({
    [classes.image]: true,
    [classes.blogImage]: blogPost,
  })
  const bodyClasses = cn({
    [classes.body]: true,
    [classes.blogBody]: blogPost,
  })
  const textBlockClasses = cn({
    [classes.textBlock]: true,
    [classes.blogTextBlock]: blogPost,
  })

  return (
    <div className={classes.wrapper}>
      <div className={containerClasses}>
        <div className={imageClasses} ref={imageRef}>
          {!isNilOrEmpty(photo) && (
            <GatsbyImage
              image={photo.gatsbyImageData}
              alt={photo.title}
              placeholder="tracedSVG"
            />
          )}
        </div>
        <div
          className={textBlockClasses}
          style={{ minHeight: imageSize.height }}
          ref={textRef}
        >
          {!isNilOrEmpty(titleText) && (
            <Text tag="h3" size="header2" className={titleClasses}>
              {titleText}
            </Text>
          )}
          {!isNilOrEmpty(paragraphText) && (
            <Text tag="p" size="body1" className={bodyClasses}>
              {paragraphText.paragraphText}
            </Text>
          )}
          {!isNilOrEmpty(dotPath('text', ctaButton)) && (
            <div className={classes.button}>
              <ContentfulButton data={ctaButton} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
